/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../node_modules/@ngx-progressbar/core/ngx-progressbar-core.ngfactory";
import * as i4 from "@ngx-progressbar/core";
import * as i5 from "@angular/router";
import * as i6 from "./app.component";
import * as i7 from "./shared/services/app-services/token.service";
import * as i8 from "@ngrx/store";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "span", [["class", "loading"]], null, null, null, null, null))], null, null); }
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "network-disconnect"], ["id", "networkStat"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.networkFeedback.reconnect; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.networkFeedback.message; _ck(_v, 2, 0, currVal_0); }); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "container animated fadeIn  text-center"], ["id", "ngCointan"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "ng-progress", [["id", "root"], ["role", "progressbar"]], [[1, "spinnerPosition", 0], [1, "dir", 0], [1, "thick", 0], [1, "fixed", 0]], null, null, i3.View_NgProgressComponent_0, i3.RenderType_NgProgressComponent)), i1.ɵdid(4, 770048, null, 0, i4.NgProgressComponent, [i4.NgProgress], { id: [0, "id"], color: [1, "color"] }, null), (_l()(), i1.ɵeld(5, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(6, 212992, null, 0, i5.RouterOutlet, [i5.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.networkStatus; _ck(_v, 2, 0, currVal_0); var currVal_5 = "root"; var currVal_6 = "#1a3d5d"; _ck(_v, 4, 0, currVal_5, currVal_6); _ck(_v, 6, 0); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 4).spinnerPosition; var currVal_2 = i1.ɵnov(_v, 4).direction; var currVal_3 = i1.ɵnov(_v, 4).thick; var currVal_4 = i1.ɵnov(_v, 4).fixed; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i6.AppComponent, [i5.Router, i4.NgProgress, i7.TokenService, i8.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i6.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
