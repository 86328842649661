import {Injectable} from '@angular/core';
import {Cookie} from '../../utilities/cookie';
import {Session} from '../../utilities/session';
import {CUSTOM_CONSTANTS} from '../../utilities/config';
import {UserAuthInterface} from '../../models/user-model';
import {EncryptionService} from './encryption.service';

@Injectable({
  providedIn: "root",
})
export class TokenService {
  constructor() {}

  public isTokenLogged(): boolean {
    return !!this.getAuthUserToken();
  }

  /**
   *
   * @param {UserAuthInterface} user
   */
  public setAuthUser(user: UserAuthInterface) {
    // let set token for the user
    // Cookie.set(CUSTOM_CONSTANTS.APP_VARIABLE, user.token_data['token'], { expires: Date.parse(user.token_data['expirer_in']) });
    // let set uer session
    Session.set(CUSTOM_CONSTANTS.APP_VARIABLE, user);
    // let set global user data
    localStorage.setItem(
      "GLOBAL_USER_DATA",
      EncryptionService.encrypt(JSON.stringify(user))
    );
  }

  getLoggedInUser() {
    return (JSON.parse(localStorage.getItem("GLOBAL_USER_DATA")));
  }

  public getAuthUser(): any {
    return (
      JSON.parse(
        EncryptionService.decrypt(localStorage.getItem("GLOBAL_USER_DATA"))
      ) || Session.get(CUSTOM_CONSTANTS.APP_VARIABLE)
    );
  }

  /**
   *
   * @return {any}
   */
  public getAuthUserToken(): any {
    return JSON.parse(
      EncryptionService.decrypt(localStorage.getItem("GLOBAL_USER_DATA"))
    )
      ? JSON.parse(
          EncryptionService.decrypt(localStorage.getItem("GLOBAL_USER_DATA"))
        ).token_data["token"]
      : null;
  }

  public getUserType(): string {
    if (localStorage.getItem("GLOBAL_USER_DATA")) {
      return JSON.parse(
        EncryptionService.decrypt(localStorage.getItem("GLOBAL_USER_DATA"))
      )["user_type"];
    }
  }

  /**
   *
   */
  private removeAuthUser(): void {
    // Cookie.remove(CUSTOM_CONSTANTS.APP_VARIABLE);
    Session.remove(CUSTOM_CONSTANTS.APP_VARIABLE);
  }

  /**
   *
   */
  public logoutToken(): void {
    this.removeAuthUser();
    Session.clear();
    localStorage.clear();
  }
}
