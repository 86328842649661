import {Injectable} from '@angular/core';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {TokenService} from './token.service';
import {NotificationService} from './notification.service';
import {ActivatedRoute, Router, RouterStateSnapshot} from '@angular/router';
import {environment as ENV} from 'src/environments/environment';
import {Store} from '@ngrx/store';
import { CUSTOM_CONSTANTS } from '../../utilities/config';

@Injectable({
  providedIn: "root",
})
export class HttpInterceptorService implements HttpInterceptor {
  constructor(
    private _tokenService: TokenService,
    private _notification: NotificationService,
    private _notificationService: NotificationService,
    private _router: Router,
    private _actRoute: ActivatedRoute
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.headers["Content-Type"] !== "application/json") {
      delete req.headers["Content-Type"];
    }

    let updatedRequest;

    // let guest have access
    updatedRequest = req.clone({
      headers: req.headers.set("api_key", `${ENV.api.KEY}`),
    });

    if (this._tokenService.isTokenLogged()) {
      updatedRequest = req.clone({
        headers: req.headers
          .set(
            "Authorization",
            `Bearer ${this._tokenService.getAuthUserToken()}`
          )
          .set("api_key", `${ENV.api.KEY}`)
          .set("platform", "admin"),
      });
    }
    // console.log('before going: ', updatedRequest);

    return next.handle(updatedRequest).pipe(
      tap(
        (res) => {
          // console.log('REQUEST', res);
        },
        (err) => {
          if (err instanceof HttpErrorResponse) {
            if (err.error.resp_code === "G101") {
              // fire login
              this._tokenService.logoutToken();
              // what im doing here is that after 2 milli-secs i wanna store the return url on LS so the user can go back to where the
              // session caught them from.. in other to make it easy to navigate
              setTimeout(() => {
                localStorage.setItem("RETURN_URL", this._router.url);
                this._router.navigate(["login"]);
              }, 2);
            } else if (err.status === 403 && err.error.resp_code === "G102") {
              console.log("Hey i am here... ");
            } else if (err.status === 422 && err.error.resp_code === "AUTH") {
              this._notificationService.showInfo(
                err.error.resp_message,
                "Authorization Request"
              );
            } else if (
              err.status === 403 &&
              !this._router.url.includes("details")
            ) {
              // let make the title page empty cos we going back to the home page
              this._router.navigate(["/access-denied"]);
            } else if (err.status === 401) {
              this._router.navigate(["login"]);
              this._tokenService.logoutToken();
            }
            else {

            }
          }
        }
      )
    );
  }
}
