import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NotificationService } from "src/app/shared/services/app-services/notification.service";
import {
  isObjectEmpty,
  passwordPreview,
  validateAllFormFields,
} from "src/app/shared/utilities/helpers";
import { AuthService } from "src/app/shared/services/api-services/auth.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { removeSpaces } from "src/app/shared/directives/common-form-validator.directive";
import { CUSTOM_CONSTANTS } from "src/app/shared/utilities/config";
import { TokenService } from "src/app/shared/services/app-services/token.service";
import { Store } from "@ngrx/store";
import { EncryptionService } from "src/app/shared/services/app-services/encryption.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  modalPhone
  username: string
  userPhone
  public state = {
    pageTitle: "Welcome, please login",
    loader: false,
    formDOM: "LOGIN",
    passwordCode: "",
    returnUrl: "",
  };

  public loginFormGroup: FormGroup;

  private static loginFormControl = () => {
    return {
      username: ["", [Validators.required, removeSpaces]],
      password: ["", [Validators.required]],
      channel: ["web"],
    };
  };

  /**
   *
   * @param {string} input
   * @returns {(elementId) => void}
   */
  public previewPassword = (input: string) => {
    return passwordPreview(input);
  };

  constructor(
    private modalService: NgbModal,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _notificationService: NotificationService,
    private _authService: AuthService,
    private _formBuilder: FormBuilder,
    private _token: TokenService
  ) {
    this.loginFormGroup = this._formBuilder.group(
      LoginComponent.loginFormControl()
    ); // assign login Form Group to the configure form control

    const paramsData = _activatedRoute.snapshot.queryParams;
    const url = this._activatedRoute.snapshot.url[0].path;
    if (url === "update-password" && !isObjectEmpty(paramsData)) {
      console.log(paramsData);
      this.switchForms("CHANGE_PASSWORD");
    } else {
      _router.navigate(["/login"]);
    }
  }

  ngOnInit() {
    // let get navigation URL to
    this.state.returnUrl = localStorage.getItem("RETURN_URL") || null;
    console.log(this.state.returnUrl);
  }

  /**
   *
   * @param {string} form
   */
  public switchForms(form: string): void {
    switch (form) {
      case "FORGOT_PASSWORD":
        this.state.pageTitle = "Request Password Reset";
        this.state.formDOM = form;
        break;
      case "CHANGE_PASSWORD":
        this.state.pageTitle = "Update Your Password";
        this.state.formDOM = form;
        break;
      default:
        this.state.pageTitle = "Welcome Back!, Please Login";
        this.state.formDOM = "LOGIN";
    }
  }

  newPassword: string
  public submitForm(phoneOtp, loginOTP): void {
    this.modalPhone = phoneOtp;

    this.username = this.loginFormGroup.value.username
    // first of all let validate the login form on submit of the form
    if (!this.loginFormGroup.valid) {
      return validateAllFormFields(this.loginFormGroup);
    }
    this.state.loader = true; // set the loading state
    if (this._token.isTokenLogged()) {
      const directUrl =
        this._token.getUserType().toUpperCase() === "ADMIN"
          ? "dashboard"
          : "agent";
      this._router.navigate([`${directUrl}`]); // navigate to the specified
      return;
    }
    // if user is not logged in register another new token from the server
    this._authService.login(this.loginFormGroup.value).subscribe(
      (res) => {
        // let log type in... since the parameter passed is valid
        localStorage.setItem("WELCOME_MESSAGE", "true");
        // set welcome messages before redirecting
        console.log(res);
        console.log(this.state.returnUrl);
        this._authService.setAuthUserToken(res["data"]);
        let REDIRECT_URL =
          res["data"].user_type.toUpperCase() === "ADMIN"
            ? "dashboard"
            : "agent";
        if (this.state.returnUrl && this.state.returnUrl !== "/login") {
          // if return url exist and and it not /login
          REDIRECT_URL = this.state.returnUrl;
        }
        this._router.navigate([`${REDIRECT_URL}`]); // navigate to the specified
      },
      (err) => {
        console.log(err);
        if (err.error.resp_code == 'C102') {
          this._authService.requestPhoneOTP(this.username).subscribe(res => {
            if (res) {
              this._notificationService.showSuccess(res.resp_description)
              this.modalService.open(phoneOtp)
            }
          });
        }
        this.state.loader = false;
        this._notificationService.showError(
          "Server Error Occurred!...500",
          err,
          CUSTOM_CONSTANTS.DEFAULT_ERROR_MESSAGE
        );
      }
    );
  }
}
