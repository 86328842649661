/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./access-denied.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./access-denied.component";
import * as i3 from "@angular/router";
var styles_AccessDeniedComponent = [i0.styles];
var RenderType_AccessDeniedComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AccessDeniedComponent, data: {} });
export { RenderType_AccessDeniedComponent as RenderType_AccessDeniedComponent };
export function View_AccessDeniedComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "section", [["class", "access-denied-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "ad-container text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", ""], ["src", "assets/images/access-denied.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "a", [["class", "btn btn-dark mt-3 waves-effect waves-yellow text-center"], ["href", "/"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "dripicons-home"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Go Back Home "]))], null, null); }
export function View_AccessDeniedComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-access-denied", [], null, null, null, View_AccessDeniedComponent_0, RenderType_AccessDeniedComponent)), i1.ɵdid(1, 114688, null, 0, i2.AccessDeniedComponent, [i3.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AccessDeniedComponentNgFactory = i1.ɵccf("app-access-denied", i2.AccessDeniedComponent, View_AccessDeniedComponent_Host_0, {}, {}, []);
export { AccessDeniedComponentNgFactory as AccessDeniedComponentNgFactory };
