import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { compareValidator } from 'src/app/shared/directives/common-form-validator.directive';
import { UserAuthInterface } from 'src/app/shared/models/user-model';
import { AuthService } from 'src/app/shared/services/api-services/auth.service';
import { EncryptionService } from 'src/app/shared/services/app-services/encryption.service';
import { NotificationService } from 'src/app/shared/services/app-services/notification.service';
import { TokenService } from 'src/app/shared/services/app-services/token.service';
import { CUSTOM_CONSTANTS } from 'src/app/shared/utilities/config';
import { validateAllFormFields } from 'src/app/shared/utilities/helpers';
import { PasswordStrengthValidator } from 'src/app/shared/utilities/strong-password';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {


  public state = {
    loader: false
  };

  public details: UserAuthInterface;

  public changePasswordForm: FormGroup;

  private static formControl() {
    return {
      old_password: ['', [Validators.required]],
      new_password: ['', [Validators.required, PasswordStrengthValidator, Validators.minLength(8)]],
      con_password: ['', [Validators.required, compareValidator('new_password')]]
    };
  }

  constructor(private _tokenService: TokenService, private _fb: FormBuilder, private _authService: AuthService, private _notificationService: NotificationService,
              private _router: Router) {
    this.changePasswordForm = this._fb.group(ResetPasswordComponent.formControl());
  }

  ngOnInit() {
    this.details = this._tokenService.getAuthUser();
    console.log(this.details);
  }

  public changePassword(): void {
    if (!this.changePasswordForm.valid) {
      return validateAllFormFields(this.changePasswordForm);
    }

    console.log(this.changePasswordForm.value);
    // return;
    this.state.loader = true;
    const formData = this.changePasswordForm.value;
    formData['old_password'] = EncryptionService.enCodeBase64(formData.old_password);
    formData['new_password'] = EncryptionService.enCodeBase64(formData.new_password);

    this._authService.changePassword(formData).subscribe(
      res => {
        this.state.loader = false;
        this._notificationService.showSuccess('Password Changed successfully, please login', CUSTOM_CONSTANTS.DEFAULT_SUCCESS_MESSAGE, 3000, true);
        this._tokenService.logoutToken();
        this._router.navigate(['/login']);
      }, err => {
        this.state.loader = false;
        this._notificationService.showError('Error Occurred', err, CUSTOM_CONSTANTS.DEFAULT_ERROR_MESSAGE);
      }
    );
  }
}
