import { HttpErrorResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { environment as ENV } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "./token.service";
import * as i2 from "./notification.service";
import * as i3 from "@angular/router";
export class HttpInterceptorService {
    constructor(_tokenService, _notification, _notificationService, _router, _actRoute) {
        this._tokenService = _tokenService;
        this._notification = _notification;
        this._notificationService = _notificationService;
        this._router = _router;
        this._actRoute = _actRoute;
    }
    intercept(req, next) {
        if (req.headers["Content-Type"] !== "application/json") {
            delete req.headers["Content-Type"];
        }
        let updatedRequest;
        // let guest have access
        updatedRequest = req.clone({
            headers: req.headers.set("api_key", `${ENV.api.KEY}`),
        });
        if (this._tokenService.isTokenLogged()) {
            updatedRequest = req.clone({
                headers: req.headers
                    .set("Authorization", `Bearer ${this._tokenService.getAuthUserToken()}`)
                    .set("api_key", `${ENV.api.KEY}`)
                    .set("platform", "admin"),
            });
        }
        // console.log('before going: ', updatedRequest);
        return next.handle(updatedRequest).pipe(tap((res) => {
            // console.log('REQUEST', res);
        }, (err) => {
            if (err instanceof HttpErrorResponse) {
                if (err.error.resp_code === "G101") {
                    // fire login
                    this._tokenService.logoutToken();
                    // what im doing here is that after 2 milli-secs i wanna store the return url on LS so the user can go back to where the
                    // session caught them from.. in other to make it easy to navigate
                    setTimeout(() => {
                        localStorage.setItem("RETURN_URL", this._router.url);
                        this._router.navigate(["login"]);
                    }, 2);
                }
                else if (err.status === 403 && err.error.resp_code === "G102") {
                    console.log("Hey i am here... ");
                }
                else if (err.status === 422 && err.error.resp_code === "AUTH") {
                    this._notificationService.showInfo(err.error.resp_message, "Authorization Request");
                }
                else if (err.status === 403 &&
                    !this._router.url.includes("details")) {
                    // let make the title page empty cos we going back to the home page
                    this._router.navigate(["/access-denied"]);
                }
                else if (err.status === 401) {
                    this._router.navigate(["login"]);
                    this._tokenService.logoutToken();
                }
                else {
                }
            }
        }));
    }
}
HttpInterceptorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HttpInterceptorService_Factory() { return new HttpInterceptorService(i0.ɵɵinject(i1.TokenService), i0.ɵɵinject(i2.NotificationService), i0.ɵɵinject(i2.NotificationService), i0.ɵɵinject(i3.Router), i0.ɵɵinject(i3.ActivatedRoute)); }, token: HttpInterceptorService, providedIn: "root" });
