<form action="" class="mt-3 animated fadeInUp" [formGroup]="updatePasswordForm" (ngSubmit)="submit()">
  <div class="form-group mb-3">
    <input type="text" placeholder="Enter Code Here" class="no-auth-input" id="up-code" formControlName="code" maxlength="6"
           [class.error]="this.updatePasswordForm.get('code').invalid && this.updatePasswordForm.get('code').touched">
    <span class="no-auth-input-icon">
      <i class="dripicons-inbox"></i>
    </span>
    <div class="form-error k-form-error" *ngIf="updatePasswordForm.get('code').invalid && (updatePasswordForm.get('code').touched ||
    updatePasswordForm.get('code').dirty)">
      <span *ngIf="updatePasswordForm.get('code').errors.required"><b>*</b> Code is required</span>
      <span *ngIf="updatePasswordForm.get('code').hasError('minlength')"><b>*</b>
        Enter 6 digit code
      </span>
      <span *ngIf="updatePasswordForm.get('code').hasError('pattern')"><b>*</b>
        Code must be in numbers
      </span>
    </div>
  </div>

  <div class="form-group mb-3">
    <input type="password" placeholder="Password" class="no-auth-input" id="up-input" formControlName="password" autocomplete="off"
           [class.error]="this.updatePasswordForm.get('password').invalid && this.updatePasswordForm.get('password').touched">
    <span class="no-auth-input-icon">
      <i class="dripicons-lock"></i>
    </span>
    <span class="input-preview-password" (click)="previewPassword('up-input')">
      <i class="dripicons-preview"></i>
    </span>
    <div class="form-error k-form-error">
      <!--            <b>*</b> Username is required-->
    </div>
    <div class="form-error k-form-error" *ngIf="updatePasswordForm.get('password').invalid && (updatePasswordForm.get('password').touched || updatePasswordForm.get('password').dirty)">
      <span *ngIf="updatePasswordForm.get('password').errors.required"><b>*</b> password is required</span>
      <span *ngIf="updatePasswordForm.get('password').hasError('passwordStrength')"><b>*</b>
      {{updatePasswordForm.get('password').errors['passwordStrength']}}
      </span>
    </div>
  </div>

  <div class="form-group mb-3">
    <input type="password" placeholder="Confirm Password" class="no-auth-input" id="ucp-input" formControlName="confirm_password" autocomplete="off"
           [class.error]="this.updatePasswordForm.get('confirm_password').invalid && this.updatePasswordForm.get('confirm_password').touched">
    <span class="no-auth-input-icon">
      <i class="dripicons-lock"></i>
    </span>
    <span class="input-preview-password" (click)="previewPassword('ucp-input')">
      <i class="dripicons-preview"></i>
    </span>
    <div class="form-error k-form-error" *ngIf="updatePasswordForm.get('confirm_password').invalid && (updatePasswordForm.get('confirm_password').touched || updatePasswordForm.get('confirm_password').dirty)">
      <span *ngIf="updatePasswordForm.get('confirm_password').errors.required"><b>*</b> Confirm password is required</span>
      <span *ngIf="updatePasswordForm.get('confirm_password').hasError('compare')"><b>*</b>
       Confirm Password do not match password
      </span>
    </div>
  </div>

  <div class="form-group mt-4">
    <button class="no-auth-btn" (click)="submit()" [disabled]="state.loader">
      <span *ngIf="!state.loader">Continue</span>
      <svg *ngIf="state.loader" xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.0" width="40px" height="30px" viewBox="0 0 128 128" xml:space="preserve"><g><path d="M59.6 0h8v40h-8V0z" fill="#ffffff" fill-opacity="1"/><path d="M59.6 0h8v40h-8V0z" fill="#fff" fill-opacity="0.2" transform="rotate(30 64 64)"/><path d="M59.6 0h8v40h-8V0z" fill="#ffffff" fill-opacity="0.2" transform="rotate(60 64 64)"/><path d="M59.6 0h8v40h-8V0z" fill="#ffffff" fill-opacity="0.2" transform="rotate(90 64 64)"/><path d="M59.6 0h8v40h-8V0z" fill="#ffffff" fill-opacity="0.2" transform="rotate(120 64 64)"/><path d="M59.6 0h8v40h-8V0z" fill="#fff" fill-opacity="0.3" transform="rotate(150 64 64)"/><path d="M59.6 0h8v40h-8V0z" fill="#fff" fill-opacity="0.4" transform="rotate(180 64 64)"/><path d="M59.6 0h8v40h-8V0z" fill="#fff" fill-opacity="0.5" transform="rotate(210 64 64)"/><path d="M59.6 0h8v40h-8V0z" fill="#fff" fill-opacity="0.6" transform="rotate(240 64 64)"/><path d="M59.6 0h8v40h-8V0z" fill="#fff" fill-opacity="0.7" transform="rotate(270 64 64)"/><path d="M59.6 0h8v40h-8V0z" fill="#fff" fill-opacity="0.8" transform="rotate(300 64 64)"/><path d="M59.6 0h8v40h-8V0z" fill="#fff" fill-opacity="0.9" transform="rotate(330 64 64)"/><animateTransform attributeName="transform" type="rotate" values="0 64 64;30 64 64;60 64 64;90 64 64;120 64 64;150 64 64;180 64 64;210 64 64;240 64 64;270 64 64;300 64 64;330 64 64" calcMode="discrete" dur="840ms" repeatCount="indefinite"></animateTransform></g></svg>
    </button>
  </div>

  <div class="text-center">
    <a (click)="goBack()"><i class="dripicons-chevron-left"></i> Back to Login</a>
  </div>
</form>
