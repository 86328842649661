import { AbstractControl, ValidationErrors } from '@angular/forms';

export const PasswordStrengthValidator = (control: AbstractControl): ValidationErrors | null  => {

  const value: string = control.value || '';

  if (!value) {
    return null;
  }

  const upperCaseCharacters = /[A-Z]+/g;
  if (upperCaseCharacters.test(value) === false) {
    return { passwordStrength: 'Password must contain uppercase character' };
  }

  // const lowerCaseCharacters = /[a-z]+/g;
  // if (lowerCaseCharacters.test(value) === false) {
  //   return { passwordStrength: `text has to contine lower case characters,current value ${value}` };
  // }


  const numberCharacters = /[0-9]+/g;
  if (numberCharacters.test(value) === false) {
    return { passwordStrength: 'Password must contain at least a number'};
  }

  const specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
  if (specialCharacters.test(value) === false) {
    return { passwordStrength: 'Password must contain special character' };
  }
  return null;
};
