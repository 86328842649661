import { environment as env } from '../../../environments/environment';
import { HttpHeaders } from '@angular/common/http';


export class Config {
  public static BASE_URL = env.api.BASE_URL;

  public headers: any = {};

  /**
   * setHeaders
   * @description this is use to set request header if it a json request
   */
  public setHeaders(Pin?: string, privateHeader?: HttpHeaders): HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers.set("Content-Type", "application/json");
    headers = headers.set("Accept", "*/*");
    headers = headers.set("platform", "admin");
    if (Pin) {
      headers = headers.set("Pin", Pin);
    }
    if (privateHeader) {
      privateHeader.keys().forEach(key => {
        headers = headers.set(key, privateHeader.get(key));
      });
    }
    return headers;
  }
}


/**
 *
 * @type {{DEFAULT_ERROR_MESSAGE: string; DEFAULT_SUCCESS_MESSAGE: string; FILE_UPLOAD: string; UNAUTHENTICATED: string; APP_VARIABLE: string; INVALID_DATA: string; ITEMS_PER_PAGE: number}}
 */
export const CUSTOM_CONSTANTS = {
  // tslint:disable-next-line:max-line-length
  ENCRYPTION_KEY: 'U2FsdGVkX19LHO5WvYAftSALKstND6fksYlNY6dQdOnJhT0R61gnfInGTIoaMhOPtZB+d8L59FhFJr0O0JNzubvCaDe/LshmdppueZjQecbxEK6puoM8udp2r4BN+/2OAyBOZ+gug08YnPw4TUKOuvx5EfSTHI0iRLhpvA2W7ZJ36o2kEesK8/VNM95gbtKKM+/tiYBouCW59V1yKjxsnQMh684NXnicgdnUqXz/RgNZCMsQ/K3eDnYTqcHc0wUHiac1ja2vyNLhPH4ko4iH9HS7aWQ56OT+4vAJFplpr5FzlsOnTyjCsiyRVDiJ8DsqutpK/KpNMHywMGRwDoFOtqi6+Q+g7/HLumzPwFu1ThjmiNUXfoDvekFrX11KOQDILJL19UBlbKrQbWKQ4WDzzHFsdgG22gSk/nq6ZT1FB4nk6OIAz9u845127Y3fWI9RnfYB43ap+aq9aEfMF4PDo4Qi5MqL4yLWyD1tdyxO5ew89OW/sD3pFFGYfDG+V6olLOkuClXRSqzMBVW612kvNAyVjdRlf3UKG99bMpmRX9JBWk7PMNqflcSSY+ahAckQVsJNrl8lVNDWGrnNlyJSC6YbclBPzBhyn1O1B3/gUuMmHByDVEiVkSc0rFHmuOLJNNzwbrbPvPt3/PGViCAZWvwFdLKs+kshXlaM3Ka9gYKUvUkU8ScOm1oZWCQOBNYBFfVneHnegOjEryBmbfeCANCOsiyGmduMMLrS27csScB5Rp2KX2RMdSG/o1qstnjNaKNqGdGb+hUMnEFfRa2WPARlXqrDcLNyvr5Pss7DdvJ7teaYwTUKw9NN7LNxzC/GxNKfdZ/zmXbUcwFjaa1kQA==',
  DEFAULT_ERROR_MESSAGE: 'Error',
  DEFAULT_SUCCESS_MESSAGE: 'Success',
  FILE_UPLOAD: 'Success',
  UNAUTHENTICATED: 'Unauthenticated',
  APP_VARIABLE: 'BAXI_DIGITAL_BANK',
  INVALID_DATA: 'The given data is invalid',
  ITEMS_PER_PAGE: 10,
  EXPORT_PAGE_ITEMS: 5000,
  USER_TYPE: ['Agents', 'Consumer', 'Beneficiary'],
  PHONE_EDIT_USERS: [
    'deborahoffor@capricorndigi.com',
    'iginla.omotayo@gmail.com',
    'segius.daniel@capricorndigi.com',
  ],
  SERVICE_REPORT_ADMIN: [
    'bright.akpe@capricorndigi.com',
    'omotayo.iginla@capricorndigi.com',
    'nkechi.nwankwo@capricorndigi.com '
  ]
};
