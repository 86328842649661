import { Routes } from '@angular/router';
import { LoginComponent } from './components/no-auth/login/login.component';
import { AccessDeniedComponent } from './shared/components/access-denied/access-denied.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { AuthGuard } from 'src/app/shared/guards/auth.guard';
import { ResetPasswordComponent } from './components/no-auth/reset-password/reset-password.component';
const ɵ0 = () => import("./components/main/main.module.ngfactory").then((mod) => mod.MainModuleNgFactory), ɵ1 = () => import("./components/agents/agents.module.ngfactory").then((mod) => mod.AgentsModuleNgFactory), ɵ2 = () => import("./components/device-owner/device-owner.module.ngfactory").then(mod => mod.DeviceOwnerModuleNgFactory);
const routes = [
    {
        path: "",
        loadChildren: ɵ0,
    },
    {
        canLoad: [AuthGuard],
        path: "agent",
        loadChildren: ɵ1,
    },
    {
        canLoad: [AuthGuard],
        path: 'device-owner',
        loadChildren: ɵ2
    },
    { path: 'login', component: LoginComponent },
    { path: 'update-password', component: LoginComponent },
    { path: 'access-denied', component: AccessDeniedComponent },
    // { path: 'reset-password', component: ChangePasswordComponent},
    { path: 'reset-password', component: ResetPasswordComponent },
    { path: 'not-found', component: NotFoundComponent },
    { path: '**', redirectTo: 'not-found' }
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2 };
