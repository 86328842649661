import {HttpErrorResponse} from '@angular/common/http';
import {CUSTOM_CONSTANTS} from './config';
import {FormControl, FormGroup} from '@angular/forms';
import * as moment from 'moment';

declare const $: any;

/**
 *
 * @param {string} type
 * @returns {boolean}
 */
export const passwordToggle = (type: string) => {
  return (type !== 'SHOW');
};

/**
 *
 * @param {string} elementId
 */
export const passwordPreview = (elementId): void => {
  const PASSWORD =   document.getElementById(elementId);
  if (PASSWORD.getAttribute('type') === 'password') {
    PASSWORD.setAttribute('type', 'text');
  } else {
    PASSWORD.setAttribute('type', 'password');
  }
};

/**
 *
 * @param {string} action
 * @param {string} modalId
 * @param {string} type
 */
export const triggerModalOrOverlay = (action: string, modalId: string, type?: string) => {
  if (type === 'STATIC') {
    $(`#${modalId}`).modal({
      backdrop: 'static',
      keyboard: false
    });
  }

  (action === 'SHOW') ? $(`#${modalId}`).modal('show') : $(`#${modalId}`).modal('hide');
  // (action === "open") ? this.overlay.open(modalId, 'slideInLeft') : this.overlay.close(modalId, () => {
  // });
  if (type === 'DRAGABLE') {
    $(`#${modalId}`).draggable({
      handle: '.modal-header'
    });
  }
};

/**
 *
 * @param {string} errorMessage
 * @param {Object | Array<string> | Array<Object>} error
 * @returns {string}
 */
export const errorHelper = (errorMessage: string, error: object | Array<string> | Array<object> | HttpErrorResponse): string => {
  let response = '';
  // first condition is to check if its an error instance
  if (error instanceof HttpErrorResponse) {
    // check if no response in connection
    if (error.status === 0) {
      response = 'Can\'t connect to the server, please check your network or contact administrator';
    }
    if (error.status === 511) {
      // TODO if session expired
      response = error.error.resp_description + '<br>';
    }

    if (error.status === 400 || error.status === 422) {
      // TODO if bad request
      if (!error.error.errors || typeof(error.error.errors) === 'string') {
        response = error.error.resp_description || error.error.resp_message + '<br>';
      } else {
        // tslint:disable-next-line: no-use-before-declare
        if (error.error.errors && isObjectEmpty(error.error.errors)) {
          response = `<i>* ${error.error.resp_description || error.error.resp_message }</i><br>`; // '<i>* ' + error.error.resp_description || 'Validation Error' + '</i><br>';
        } else {
          response = `<i>* Validation Error</i><br>`;
          for (const err in error.error.errors) {
            if (error.error.errors.hasOwnProperty(err)) {
              error.error.errors[err].forEach((e) => {
                response += '. ' + e + '<br>';
              });
            }
          }
        }
      }
    }
  } else {
    response = error && error['error'] ? error['error']['resp_description'] || error['error']['resp_message'] : errorMessage;
  }
  return response || error['error']['resp_description'] || error['error']['resp_message'] || errorMessage;
};

/**
 *
 * @param arr
 * @param obj
 */
export  const pushToArray = (arr, obj) => {
  const index = arr.findIndex((e) => e.id === obj.id);
  if (index === -1) {
    arr.unshift(obj);
  } else {
    arr[index] = obj;
  }
};

/**
 *
 * @param arr
 * @param obj
 */
export const removeFromArray = (arr, obj) => {
  const index = arr.findIndex(e => e.id === obj.id);
  if (index === -1) {
    return;
  } else {
    arr.splice(index, 1);
  }
};

/**
 *
 * @param object
 */
export const setObjectValuesToNull = (object) => {
  for (const key in object) {
    if (object.hasOwnProperty(key)) {
      object[key] = '';
    }
  }
};

/**
 *
 * @param {object} obj
 * @returns {string[]}
 */
export const convertObjectToArray = (obj: object): string[] => {

  const convertedArray = [];
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      convertedArray.push(obj[key]);
    }
  }
  return convertedArray;

};

/**
 *
 * @param value
 */
export const UCFirst = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};


/**
 *
 * @param originalArray
 * @param props
 * @returns {any[]}
 */
export const removeArrayDuplicate = (originalArray, props?) => {
    let newArray = [];
    const lookupObject  = {};

    if (props) {

      // tslint:disable-next-line: forin
      for (const i in originalArray) {
        lookupObject[originalArray[i][props]] = originalArray[i];
      }

      // tslint:disable-next-line: forin
      for (const i in lookupObject) {
        newArray.push(lookupObject[i]);
      }

      return newArray;

    }

    newArray = Array.from(new Set(originalArray));
    return newArray;

};


/**
 * check if obj is empty
 * @param obj
 * @returns {boolean}
 */
export const isObjectEmpty = (obj) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
};

/**
 * check if input is empty or white space
 * @param str
 * @returns {boolean}
 */
export const isEmptyOrSpaces = (str) => {
  return str === null || str.trim().length > 0;
};


/**
 * reload page
 */
export const reloadPage = () => {
  return window.location.reload();
};

/**
 *
 * @param value
 * @param array
 * @return {boolean}
 */
export const isInArray = (value, array) => {
  return array.indexOf(value) > -1;
};

/**
 *
 * @param {FormGroup} formGroup
 */
export const validateAllFormFields = (formGroup: FormGroup) => {         // {1}
  Object.keys(formGroup.controls).forEach(field => {  // {2}
    const control = formGroup.get(field);             // {3}
    if (control instanceof FormControl) {             // {4}
      control.markAsTouched({ onlySelf: true });
    } else if (control instanceof FormGroup) {        // {5}
      validateAllFormFields(control);            // {6}
    }
  });
};

/**
 * Comma separate amount inputs
 * @param text
 * @param element_id
 */
export const specify = (text, element_id?: string) => {
  const value = text.replace(/,/g, '');
  const matches = /^(?:(\d{1,3})?((?:\d{3})*))((?:,\d*)?)$/.exec(value);
  if (!matches) {
    return;
  }

  // add a comma before every group of three digits
  const modified = matches[2].replace(/(\d{3})/g, ',$1');

  // now splice it all back together
  const result = [matches[1], modified, matches[3]].join('');
  if (element_id) {
    const amountElement = (document.getElementById(element_id) as HTMLInputElement);
    const cursorPosition = amountElement.selectionStart;
    // console.log(cursorPosition);
    amountElement.value = result;
    // setCaretPosition(amountElement, cursorPosition);
  }
  return result;
};

/**
 *
 * @param evt
 */
export const isNumberKey = (evt) => {
  const theEvent = evt || window.event;
  let key;

  if (theEvent.type === 'paste') {
    key = evt.clipboardData.getData('text/plain');
  } else {
    // Handle key press
    key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
  }
  const regex = /[0-9]|\./;
  if ( !regex.test(key) ) {
    theEvent.returnValue = false;
    if (theEvent.preventDefault) {
      theEvent.preventDefault();
    }
  }
};

export const isAlphaKey = (evt) => {
  const theEvent = evt || window.event;
  let key;

  if (theEvent.type === 'paste') {
    key = evt.clipboardData.getData('text/plain');
  } else {
    // Handle key press
    key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
  }
  const regex = /[A-Z-a-z]|\./;
  if ( !regex.test(key) ) {
    theEvent.returnValue = false;
    if (theEvent.preventDefault) {
      theEvent.preventDefault();
    }
  }
};

/**
 *
 * @param arr
 * @param initValue
 */
export const reduce = (arr: Array<any>, initValue = 0) => {
  return arr.reduce((a, c) => {
    return a + Number(c['value'] || c);
  }, initValue);
};

/**
 *
 * @param payload
 * @param type
 */
export const dateConverter = (payload: any, type: string) => {
  let date: any;
  if (type === 'DOUBLE') {
    if (!payload || (payload && payload.dateRange === '') || (payload && payload.dateRange === null) || payload.dateRange === undefined) {
      date = {startDate: '', endDate: ''};
    } else {
      date = {
        startDate: moment(payload && payload.dateRange[0]).format('YYYY-MM-DD'),
        endDate: moment(payload && payload.dateRange[1]).format('YYYY-MM-DD')
      };
    }
  } else {
    date = moment(payload.date || payload).format('YYYY-MM-DD');
  }
  return date;
};


export const isFile = (input) => {
  return input && input.files;
};

/**
 *
 * @param {string} email
 * @returns {string}
 */
export const maskEmail = (email: string) => {
  if (email !== null) {
    const skipFirstChars = 3;
    const firstThreeChar = email.slice(0, skipFirstChars);
    const domainIndexStart = email.lastIndexOf("@");
    let maskedEmail = email.slice(skipFirstChars, domainIndexStart);
    maskedEmail = maskedEmail.replace(/./g, "*");
    const domain = email.slice(domainIndexStart, email.length);
    return firstThreeChar.concat(maskedEmail).concat(domain);
  }
};


export const statusColor = (value) => {
  if (value === 'Successful')
    return 'green';
  else
    return 'red'
};
