import {Action} from '@ngrx/store';

export enum UserAction {
  creatUser = '[CREATE] user',
  readUser = '[READ] user',
  editUser = '[EDIT] user',
  toggleUser = '[TOGGLE] user'
}

export class UsersCreationAction implements Action {
  readonly type = UserAction.creatUser;
  constructor(public payload: any) {
  }
}

export class UsersReadAction implements Action {
  readonly type = UserAction.readUser;
  constructor(public payload: any) {
  }
}

export class UsersEditAction implements Action {
  readonly type = UserAction.editUser;
  constructor(public payload: any) {
  }
}

export type Actions = UsersCreationAction | UsersReadAction | UsersEditAction;
