import { Injectable } from '@angular/core';
import {
  CanActivate, CanActivateChild, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree,
  Router
} from '@angular/router';
// @ts-ignore
import { Observable } from 'rxjs';
import {AuthService} from '../services/api-services/auth.service';
import { TokenService } from '../services/app-services/token.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {

  constructor(
    private _auth: AuthService, private _router: Router, private _tokenService: TokenService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this._auth.isLoggedIn()) {
      return this._auth.isLoggedIn();
    } else {
      this._tokenService.logoutToken();
      this._router.navigate(['login']);
      return false;
    }
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this._auth.isLoggedIn()) {
      return this._auth.isLoggedIn();
    } else {
      this._tokenService.logoutToken();
      this._router.navigate(['login']);
      return false;
    }
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    if (this._auth.isLoggedIn()) {
      return true;
    } else {
      this._tokenService.logoutToken();
      this._router.navigate(['login']);
      return false;
    }
  }
}
