<div class="no-auth-container">
  <div class="no-auth-form-wrapper">
    <div class="form-holder">
      <div class="p-3 text-center form-top mb-3">

        <div class="logo">
          <img src="assets/images/baxibox-loader.png" alt="">
        </div>
        <div class="title">
          <h3 >Reset Password</h3>
        </div>
      </div>

      <main class="forms animated fadeInUp card">
      <form action="" class="p-4 shadow-lg" [formGroup]="changePasswordForm" (ngSubmit)="changePassword()">
        <div class="form-group">
          <label for="oldPassword">Current Password</label>
          <input type="password" id="oldPassword" class="form-control" placeholder="Enter Current Password"
            formControlName="old_password"
            [ngClass]="{'has-error': changePasswordForm.get('old_password')?.invalid && changePasswordForm.get('old_password')?.touched}">
          <div class="k-form-error"
            *ngIf="changePasswordForm.get('old_password')?.invalid && (changePasswordForm.get('old_password')?.touched || changePasswordForm.get('old_password').dirty)">
            <span *ngIf="changePasswordForm.get('old_password').hasError('required')"><b>*</b> Current password is
              required</span>
          </div>
        </div>

        <div class="form-group">
          <label for="newPassword">New Password</label>
          <input type="password" id="newPassword" class="form-control" placeholder="Enter New Password"
            formControlName="new_password"
            [ngClass]="{'has-error': changePasswordForm.get('new_password')?.invalid && changePasswordForm.get('new_password')?.touched}">
          <div class="k-form-error"
            *ngIf="changePasswordForm.get('new_password')?.invalid && (changePasswordForm.get('new_password')?.touched || changePasswordForm.get('new_password').dirty)">
            <span *ngIf="changePasswordForm.get('new_password').hasError('required')"><b>*</b> Current password is
              required</span>
            <span *ngIf="changePasswordForm.get('new_password').hasError('minlength')"><b>*</b> new password must be more than
              8 characters</span><br>
            <span *ngIf="changePasswordForm.get('new_password').hasError('passwordStrength')"><b>*</b>
              {{changePasswordForm.get('new_password').errors['passwordStrength']}}
            </span>
          </div>
        </div>

        <div class="form-group">
          <label for="conPassword">Confirm Password</label>
          <input type="password" id="conPassword" class="form-control" placeholder="Confirm Password"
            formControlName="con_password"
            [ngClass]="{'has-error': changePasswordForm.get('con_password')?.invalid && changePasswordForm.get('con_password')?.touched}">
          <div class="k-form-error"
            *ngIf="changePasswordForm.get('con_password')?.invalid && (changePasswordForm.get('con_password')?.touched || changePasswordForm.get('con_password').dirty)">
            <span *ngIf="changePasswordForm.get('con_password').hasError('required')"><b>*</b> Confirm password is
              required</span>
            <span *ngIf="changePasswordForm.get('con_password').hasError('compare')"><b>*</b>
              Confirm Password do not match
            </span>
          </div>
        </div>

        <button type="submit" class="btn primary-btn">
          Submit <i *ngIf="state.loader" class="ion ion-load-c fa-spin"></i>
        </button>
      </form>

      </main>
   <!-- <form action="" class="p-4 shadow-lg" [formGroup]="changePasswordForm" (ngSubmit)="changePassword()">
                  <div class="form-group">
                    <label for="oldPassword">Current Password</label>
                    <input type="password" id="oldPassword" class="form-control" placeholder="Enter Current Password" formControlName="old_password"
                           [ngClass]="{'has-error': changePasswordForm.get('old_password')?.invalid && changePasswordForm.get('old_password')?.touched}">
                    <div class="k-form-error" *ngIf="changePasswordForm.get('old_password')?.invalid && (changePasswordForm.get('old_password')?.touched || changePasswordForm.get('old_password').dirty)">
                      <span *ngIf="changePasswordForm.get('old_password').hasError('required')"><b>*</b> Current password is required</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="newPassword">New Password</label>
                    <input type="password" id="newPassword" class="form-control" placeholder="Enter New Password" formControlName="new_password"
                           [ngClass]="{'has-error': changePasswordForm.get('new_password')?.invalid && changePasswordForm.get('new_password')?.touched}">
                    <div class="k-form-error" *ngIf="changePasswordForm.get('new_password')?.invalid && (changePasswordForm.get('new_password')?.touched || changePasswordForm.get('new_password').dirty)">
                      <span *ngIf="changePasswordForm.get('new_password').hasError('required')"><b>*</b> Current password is required</span>
                      <span *ngIf="changePasswordForm.get('new_password').hasError('minlength')"><b>*</b> new password must be more than 8 characters</span><br>
                      <span *ngIf="changePasswordForm.get('new_password').hasError('passwordStrength')"><b>*</b>
                          {{changePasswordForm.get('new_password').errors['passwordStrength']}}
                      </span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="conPassword">Confirm Password</label>
                    <input type="password" id="conPassword" class="form-control" placeholder="Confirm Password" formControlName="con_password"
                           [ngClass]="{'has-error': changePasswordForm.get('con_password')?.invalid && changePasswordForm.get('con_password')?.touched}">
                    <div class="k-form-error" *ngIf="changePasswordForm.get('con_password')?.invalid && (changePasswordForm.get('con_password')?.touched || changePasswordForm.get('con_password').dirty)">
                      <span *ngIf="changePasswordForm.get('con_password').hasError('required')"><b>*</b> Confirm password is required</span>
                      <span *ngIf="changePasswordForm.get('con_password').hasError('compare')"><b>*</b>
                      Confirm Password do not match
                    </span>
                    </div>
                  </div>

                  <button type="submit" class="btn primary-btn">
                    Submit <i *ngIf="state.loader" class="ion ion-load-c fa-spin"></i>
                  </button>
                </form> -->
    </div>
  </div>
</div>







