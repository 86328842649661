import * as UserActions from 'src/app/store/actions/users.action';
import {UserAction} from 'src/app/store/actions/users.action';

export interface UsersReducerInterface {
  action: string;
  payload: any;
}

const initialState: UsersReducerInterface = {
  action: '',
  payload: ''
};

export function userReducer(state: UsersReducerInterface = initialState, action: UserActions.Actions) {
  switch (action.type) {
    case UserAction.creatUser :
      return {...state,  ...action.payload};
    case UserAction.readUser:
      return {...state, ...action.payload};
    case UserAction.editUser :
      return {...state, ...action.payload};
    default :
      return state;
  }
}
