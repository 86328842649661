import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Config } from '../../utilities/config';
import { isFile } from 'src/app/shared/utilities/helpers';
declare const $: any;

@Injectable({
  providedIn: "root",
})
export class ApiReqHandlersService extends Config {
  constructor(private http: HttpClient) {
    super();
  }

  /**
   * post
   * @param path
   * @param data
   * @returns Observable
   */
  public post(path: string, data?: any, pin?: string, headers?: HttpHeaders): Observable<any> {
    this.headers = { headers: this.setHeaders(pin, headers) };
    const url = `${ApiReqHandlersService.BASE_URL}${path}`;
    return this.http.post(url, data || {}, this.headers);
  }

  /**
   *
   * @param {string} path
   * @returns {Observable<any>}
   */
  public get(path: string, headers?: HttpHeaders): Observable<any> {
    this.headers = { headers: this.setHeaders(null, headers) };
    const url = `${ApiReqHandlersService.BASE_URL}${path}`;
    return this.http.get(url, this.headers);
  }

  /**
   *
   * @param {string} path
   * @param data
   * @returns {Observable<any>}
   */
  public put(path: string, data?: any): Observable<any> {
    this.headers = { headers: this.setHeaders() };
    const url = `${ApiReqHandlersService.BASE_URL}${path}`;
    return this.http.put(url, data, this.headers);
  }

  /**
   *
   * @param {string} path
   * @param data
   * @returns {Observable<any>}
   */
  public patch(path: string, data?: any): Observable<any> {
    this.headers = { headers: this.setHeaders() };
    const url = `${ApiReqHandlersService.BASE_URL}${path}`;
    return this.http.patch(url, data, this.headers);
  }

  /**
   *
   * @param path
   * @param data
   */
  public delete(path: string, data?: any): Observable<any> {
    this.headers = { headers: this.setHeaders() };
    const url = `${ApiReqHandlersService.BASE_URL}${path}`;
    return this.http.delete(url, this.headers);
  }

  /**
   * @param urlLink
   * @param formFile
   * @param file_key
   * @param data
   * @param method
   */
  public postFile(
    urlLink: string,
    formFile,
    file_key = "file",
    data?,
    method?,
    formKeys?
  ): Observable<any> {
    const header = this.setHeaders("multipart/form-data");
    this.headers = { headers: header };

    const urlPath = urlLink;
    var formData = new FormData();
    let file = null;
    if (formFile && formFile.files[0]) {
      file = formFile.files[0];
      formData.append(file_key, file, file.name);
    }

    if (data !== null) {
      Object.keys(data).forEach((e) => {
        if (!isFile(data[e])) {
          formData.append(e, String(data[e]));
        }
      });
    }
    //  formData.forEach((value, key) => {
    //    console.log("Form KEY AND VALUE .... ", key + " " + value);
    //  });
    // // formData.append("body", data);
    // // return;
    const url = `${ApiReqHandlersService.BASE_URL}${urlPath}`;
    return this.http.post(url, formData);
  }

  /**
   *
   * @param data
   * @param formFiles
   * @param urlLink
   * @param file_key
   */
  public postMultipleFile(
    data: any,
    formFiles,
    urlLink: string,
    file_key = "file"
  ): Observable<any> {
    const header = this.setHeaders();
    header.delete("Content-Type");
    this.headers = { headers: header };
    const path = $.param(data);
    const urlPath =
      Object.keys(data).length > 0 ? `${urlLink}?${path}` : urlLink;
    const formData = new FormData();
    let file = null;

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < formFiles.length; i++) {
      if (formFiles[i]) {
        file = formFiles[i];
        formData.append(file_key, file, file.name);
      }
      formData.append("body", data);
    }
    const url = `${ApiReqHandlersService.BASE_URL}${urlPath}`;
    return this.http.post(url, formData || {}, this.headers);
  }
}
