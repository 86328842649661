<form action="" class="mt-3 animated flipInY" [formGroup]="forgotPasswordForm">
  <div class="form-group mb-3">
    <input type="email" placeholder="Enter your email address" class="no-auth-input" formControlName="email"
           [class.error]="this.forgotPasswordForm.get('email').invalid && this.forgotPasswordForm.get('email').touched">
    <span class="no-auth-input-icon">
      <i class="dripicons-message"></i>
    </span>
    <div class="form-error k-form-error" *ngIf="forgotPasswordForm.get('email').invalid &&
    (forgotPasswordForm.get('email').touched || forgotPasswordForm.get('email').dirty)">
      <span *ngIf="forgotPasswordForm.get('email').errors.required || forgotPasswordForm.get('email').errors.whitespace"><b>*</b> Email address is required</span>
      <span *ngIf="forgotPasswordForm.get('email').errors.email ">Enter a valid email address</span>
    </div>
  </div>

  <div class="form-group mt-4">
    <button class="no-auth-btn" (click)="submit()" [disabled]="state.loader">
      <span *ngIf="!state.loader">Request reset</span>
      <svg *ngIf="state.loader" xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.0" width="40px" height="30px" viewBox="0 0 128 128" xml:space="preserve"><g><path d="M59.6 0h8v40h-8V0z" fill="#ffffff" fill-opacity="1"/><path d="M59.6 0h8v40h-8V0z" fill="#fff" fill-opacity="0.2" transform="rotate(30 64 64)"/><path d="M59.6 0h8v40h-8V0z" fill="#ffffff" fill-opacity="0.2" transform="rotate(60 64 64)"/><path d="M59.6 0h8v40h-8V0z" fill="#ffffff" fill-opacity="0.2" transform="rotate(90 64 64)"/><path d="M59.6 0h8v40h-8V0z" fill="#ffffff" fill-opacity="0.2" transform="rotate(120 64 64)"/><path d="M59.6 0h8v40h-8V0z" fill="#fff" fill-opacity="0.3" transform="rotate(150 64 64)"/><path d="M59.6 0h8v40h-8V0z" fill="#fff" fill-opacity="0.4" transform="rotate(180 64 64)"/><path d="M59.6 0h8v40h-8V0z" fill="#fff" fill-opacity="0.5" transform="rotate(210 64 64)"/><path d="M59.6 0h8v40h-8V0z" fill="#fff" fill-opacity="0.6" transform="rotate(240 64 64)"/><path d="M59.6 0h8v40h-8V0z" fill="#fff" fill-opacity="0.7" transform="rotate(270 64 64)"/><path d="M59.6 0h8v40h-8V0z" fill="#fff" fill-opacity="0.8" transform="rotate(300 64 64)"/><path d="M59.6 0h8v40h-8V0z" fill="#fff" fill-opacity="0.9" transform="rotate(330 64 64)"/><animateTransform attributeName="transform" type="rotate" values="0 64 64;30 64 64;60 64 64;90 64 64;120 64 64;150 64 64;180 64 64;210 64 64;240 64 64;270 64 64;300 64 64;330 64 64" calcMode="discrete" dur="840ms" repeatCount="indefinite"></animateTransform></g></svg>
    </button>
  </div>

  <div class="text-center">
    <a (click)="goBack()"><i class="dripicons-chevron-left"></i> Back to Login</a>
  </div>
</form>
