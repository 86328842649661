import { Session } from '../../utilities/session';
import { CUSTOM_CONSTANTS } from '../../utilities/config';
import { EncryptionService } from './encryption.service';
import * as i0 from "@angular/core";
export class TokenService {
    constructor() { }
    isTokenLogged() {
        return !!this.getAuthUserToken();
    }
    /**
     *
     * @param {UserAuthInterface} user
     */
    setAuthUser(user) {
        // let set token for the user
        // Cookie.set(CUSTOM_CONSTANTS.APP_VARIABLE, user.token_data['token'], { expires: Date.parse(user.token_data['expirer_in']) });
        // let set uer session
        Session.set(CUSTOM_CONSTANTS.APP_VARIABLE, user);
        // let set global user data
        localStorage.setItem("GLOBAL_USER_DATA", EncryptionService.encrypt(JSON.stringify(user)));
    }
    getLoggedInUser() {
        return (JSON.parse(localStorage.getItem("GLOBAL_USER_DATA")));
    }
    getAuthUser() {
        return (JSON.parse(EncryptionService.decrypt(localStorage.getItem("GLOBAL_USER_DATA"))) || Session.get(CUSTOM_CONSTANTS.APP_VARIABLE));
    }
    /**
     *
     * @return {any}
     */
    getAuthUserToken() {
        return JSON.parse(EncryptionService.decrypt(localStorage.getItem("GLOBAL_USER_DATA")))
            ? JSON.parse(EncryptionService.decrypt(localStorage.getItem("GLOBAL_USER_DATA"))).token_data["token"]
            : null;
    }
    getUserType() {
        if (localStorage.getItem("GLOBAL_USER_DATA")) {
            return JSON.parse(EncryptionService.decrypt(localStorage.getItem("GLOBAL_USER_DATA")))["user_type"];
        }
    }
    /**
     *
     */
    removeAuthUser() {
        // Cookie.remove(CUSTOM_CONSTANTS.APP_VARIABLE);
        Session.remove(CUSTOM_CONSTANTS.APP_VARIABLE);
    }
    /**
     *
     */
    logoutToken() {
        this.removeAuthUser();
        Session.clear();
        localStorage.clear();
    }
}
TokenService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TokenService_Factory() { return new TokenService(); }, token: TokenService, providedIn: "root" });
