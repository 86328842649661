
     <div class="container-fluid">
        <form (ngSubmit)="submit()" [formGroup]="form">
            <div class="row">
            <div class="form-group" *ngFor="let input of formInput;  index as i">
          <input #formRow type="text" formControlName="{{input}}" class="form-control" maxlength="1" (keyup)="keyUpEvent($event, i)">
            </div>
            </div>
            <button type="submit" [disabled]="!form.valid" class="btn btn-block  btn-warning ">Submit</button>
            </form>
      </div>
    