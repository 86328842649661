import { Component, EventEmitter, Input, OnInit, Output, ViewChildren } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AuthService } from 'src/app/shared/services/api-services/auth.service';
import { NotificationService } from 'src/app/shared/services/app-services/notification.service';
import { TokenService } from 'src/app/shared/services/app-services/token.service';



@Component({
  selector: 'app-login-otp',
  template: `
     <div class="container-fluid">
        <form (ngSubmit)="submit()" [formGroup]="form">
            <div class="row">
            <div class="form-group" *ngFor="let input of formInput;  index as i">
          <input #formRow type="text" formControlName="{{input}}" class="form-control" maxlength="1" (keyup)="keyUpEvent($event, i)">
            </div>
            </div>
            <button type="submit" [disabled]="!form.valid" class="btn btn-block  btn-warning ">Submit</button>
            </form>
      </div>
    `,
  styles: [`
          form input{
            display:inline-block;
            width:45px;
            height:45px;
            text-align:center;
            margin:5px
          }
   `]
})


export class LoginOtpComponent implements OnInit  {


  form
  @Input() userData: any;
  @Input() phoneOtp : any;
  @Input() username
  @Input() userPhone
  @Output()   submitOTPValue  = new EventEmitter<any>();
  formInput = ['int1', 'int2', 'int3', 'int4', 'int5', 'int6', 'int7', 'int8'];
  @ViewChildren('formRow') rows: any;

   modalRef: BsModalRef;
  constructor(
    private _route: Router,
    private notificationService : NotificationService,
    private _authService: AuthService,private _token: TokenService)  {
    this.form = this.toFormGroup(this.formInput);}
  ngOnInit() { }


  toFormGroup(elements) {
    const group: any = {};
    elements.forEach(key => {
      group[key] = new FormControl('', Validators.required);
    });
    return new FormGroup(group);
  }

  keyUpEvent(event, index) {
      let pos = index;
      if (event.keyCode === 8 && event.which === 8) {
        pos = index - 1 ;
      } else {
        pos = index + 1 ;
      }
      if (pos > -1 && pos < this.formInput.length ) {
        this.rows._results[pos].nativeElement.focus();
      }
    }

  submit() {

    let otpNumber = Object.values(this.form.value).join("");
    // console.log(this.userData)
    this._authService.sendLoginOTP(otpNumber, this.userData.username)
       .subscribe(result => {
          if (result) {
            this.notificationService.showSuccess(result.resp_description)
             this._authService.login(this.userData).subscribe(res => {})
          }
       })

  }

}

  //  this._authService.sendLoginOTP(`${this.a}${this.b}${this.c}${this.d}${this.e}${this.f}${this.g}${this.h}`, this.userData.username)
