import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {passwordPreview, validateAllFormFields} from 'src/app/shared/utilities/helpers';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NotificationService} from 'src/app/shared/services/app-services/notification.service';
import {AuthService} from 'src/app/shared/services/api-services/auth.service';
import {compareValidator} from 'src/app/shared/directives/common-form-validator.directive';
import {PasswordStrengthValidator} from 'src/app/shared/utilities/strong-password';
import {CUSTOM_CONSTANTS} from 'src/app/shared/utilities/config';
import { EncryptionService } from 'src/app/shared/services/app-services/encryption.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  state = {
    loader: false
  };

  public updatePasswordForm: FormGroup;

  static updatePasswordControl = () => {
    return {
      password: ['', [Validators.required, PasswordStrengthValidator]],
      confirm_password: ['', [Validators.required, compareValidator('password')]],
      code: ['', [Validators.required, Validators.minLength(6), Validators.pattern(/[0-9]/)]]
    };
  }

  /**
   *
   * @param {string} input
   */
  public previewPassword = (input: string) => {
    return passwordPreview(input);
  }

  constructor(private _router: Router, private _FB: FormBuilder, private _notificationService: NotificationService,
              private _authService: AuthService) {
    this.updatePasswordForm = _FB.group(ChangePasswordComponent.updatePasswordControl());
  }

  ngOnInit() {
  }

  public goBack(): void {
    this._router.navigate(['/login']);
  }

  public submit(): void {
    // console.log('this is a function');
    // first of all let validate the login form on submit of the form
    if (!this.updatePasswordForm.valid) {
      return validateAllFormFields(this.updatePasswordForm);
    }
    this.state.loader = true;
    console.log(this.updatePasswordForm.value);
    const payload = this.updatePasswordForm.value;
    payload['password'] = EncryptionService.enCodeBase64(payload.password);
    payload['confirm_password'] = EncryptionService.enCodeBase64(payload.confirm_password);

    console.log(payload);
    // return
    this._authService.updatePassword(payload).subscribe(
      res => {
        this.state.loader = false;
        console.log(res);
        this._notificationService.showSuccess(res.resp_description, CUSTOM_CONSTANTS.DEFAULT_SUCCESS_MESSAGE);
        this.goBack();
      }, err => {
        this.state.loader = false;
        console.log(err);
        this._notificationService.showError('Error Occurred', err, `${err.error ? err.error.resp_message : CUSTOM_CONSTANTS.DEFAULT_ERROR_MESSAGE}`);
      }
    );
  }
}
