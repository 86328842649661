import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PolicyComponent } from '../components/users/policy/policy.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddUnderscoreAndDash } from '../pipes/addUnderscore.pipe';
import { MomentDatePipe } from '../pipes/moment-date.pipe';
import { RemoveUnderscoreAndDashPipe } from '../pipes/removeUnderscore.pipe';
import { MatchValueDirective, NoWhitespaceDirective } from '../directives/common-form-validator.directive';
import { SanitizeHtmlPipe } from '../pipes/sanitize.pipe';
import { Sort } from '../pipes/sort.pipe';
import { NgxPaginationModule } from 'ngx-pagination';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { UserDetailsComponent } from '../components/users/user-details/user-details.component';
import { UserListComponent } from 'src/app/shared/components/users/user-list/user-list.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharingFormulaComponent } from 'src/app/shared/components/settings/sharing-formula/sharing-formula.component';
import { WalletComponent } from 'src/app/shared/components/users/wallet/wallet.component';
import { WalletHistoryComponent } from 'src/app/shared/components/users/wallet-history/wallet-history.component';
import { RouterModule } from '@angular/router';
import { ServicesComponent } from 'src/app/shared/components/users/services/services.component';
import { TransactionsComponent } from 'src/app/shared/components/users/transactions/transactions.component';
import { ClickOutsideDirective } from 'src/app/shared/directives/click-outside.directive';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { PageFilterComponent } from '../components/misc/page-filter/page-filter.component';

import { NumberFormater } from '../pipes/numberFormater.pipe';
import {ChartsModule, ThemeService} from 'ng2-charts';
import { SecondaryUserListComponent } from '../components/users/secondary-user-list/secondary-user-list.component';
import { RecurringBillingsComponent } from '../components/users/recurring-billings/recurring-billings.component';
import { JsonParserPipe } from '../pipes/json-parser.pipe';
import {AutoSettlementComponent} from 'src/app/shared/components/users/auto-settlement/auto-settlement.component';
import {DlDateTimeDateModule, DlDateTimeInputModule, DlDateTimePickerModule} from 'angular-bootstrap-datetimepicker';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import { ContentViewer } from '../directives/dynamic-content-viewer';
import {CustomFilterPipe} from 'src/app/shared/pipes/custom-filter.pipe';
import {KycComponent} from 'src/app/shared/components/kyc/kyc.component';
import {KycUpdaterComponent} from 'src/app/shared/components/kyc-updater/kyc-updater.component';
import { PermissionComponent } from '../components/users/permission/permission.component';
import { SettlementComponent } from "../components/settlement/settlement.component";

import { DownloadkycComponent } from '../components/kyc/downloadkyc/downloadkyc.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { DateTimePickerComponent } from "../components/datetime-picker/datetime-picker.component";
import {
  NgbDropdownModule,
  NgbDatepickerModule,
  NgbTimepickerModule,
} from "@ng-bootstrap/ng-bootstrap";
import { TimepickerModule } from "ngx-bootstrap/timepicker";
import { SweepComponent } from '../components/sweep/sweep.component';
import { DatePipe } from "@angular/common";
import { ApprovalComponent } from '../components/approval/approval.component';
import { UserCreateComponent } from '../components/users/user-create/user-create.component';
import { ReceiptComponent } from '../components/receipt/receipt.component';
import { KycNewLevelsComponent } from '../components/kyc-new-levels/kyc-new-levels.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

@NgModule({
  declarations: [
    UserCreateComponent,
    UserDetailsComponent,
    UserListComponent,
    AddUnderscoreAndDash,
    MomentDatePipe,
    RemoveUnderscoreAndDashPipe,
    NumberFormater,
    NoWhitespaceDirective,
    MatchValueDirective,
    ClickOutsideDirective,
    SanitizeHtmlPipe,
    Sort,
    SharingFormulaComponent,
    WalletComponent,
    WalletHistoryComponent,
    ServicesComponent,
    TransactionsComponent,
    PageFilterComponent,
    SecondaryUserListComponent,
    RecurringBillingsComponent,
    AutoSettlementComponent,
    JsonParserPipe,
    ContentViewer,
    CustomFilterPipe,
    KycComponent,
    DownloadkycComponent,
    KycUpdaterComponent,
    PolicyComponent,
    PermissionComponent,
    SettlementComponent,
    DateTimePickerComponent,
    SweepComponent,
    ApprovalComponent,
    ReceiptComponent,
    KycNewLevelsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    PopoverModule.forRoot(),
    NgxPaginationModule,
    NgSelectModule,
    ChartsModule,
    RouterModule,
    NgbModule,
    NgbDropdownModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    TimepickerModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot()
    // UserTableComponentModule,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    AddUnderscoreAndDash,
    MomentDatePipe,
    RemoveUnderscoreAndDashPipe,
    NumberFormater,
    NoWhitespaceDirective,
    ClickOutsideDirective,
    UserListComponent,
    UserCreateComponent,
    NgxPaginationModule,
    NgSelectModule,
    TooltipModule,
    BsDropdownModule,
    BsDatepickerModule,
    PopoverModule,
    ClipboardModule,
    ChartsModule,
    PageFilterComponent,
    JsonParserPipe,
    DlDateTimeDateModule,
    DlDateTimePickerModule,
    DlDateTimeInputModule,
    ContentViewer,
    CustomFilterPipe,
    KycComponent,
    KycUpdaterComponent,
    SettlementComponent,
    DateTimePickerComponent,
    SweepComponent,
    ApprovalComponent,
    WalletHistoryComponent,
    ReceiptComponent,
    KycNewLevelsComponent
  ],
  providers: [DatePipe],
})
export class SharedModule {}
