import * as i0 from "@angular/core";
import * as i1 from "../services/api-services/auth.service";
import * as i2 from "@angular/router";
import * as i3 from "../services/app-services/token.service";
export class AuthGuard {
    constructor(_auth, _router, _tokenService) {
        this._auth = _auth;
        this._router = _router;
        this._tokenService = _tokenService;
    }
    canActivate(next, state) {
        if (this._auth.isLoggedIn()) {
            return this._auth.isLoggedIn();
        }
        else {
            this._tokenService.logoutToken();
            this._router.navigate(['login']);
            return false;
        }
    }
    canActivateChild(next, state) {
        if (this._auth.isLoggedIn()) {
            return this._auth.isLoggedIn();
        }
        else {
            this._tokenService.logoutToken();
            this._router.navigate(['login']);
            return false;
        }
    }
    canLoad(route, segments) {
        if (this._auth.isLoggedIn()) {
            return true;
        }
        else {
            this._tokenService.logoutToken();
            this._router.navigate(['login']);
            return false;
        }
    }
}
AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.TokenService)); }, token: AuthGuard, providedIn: "root" });
