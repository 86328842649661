import * as DOMActions from '../actions/dom-changes.action';
import { DomChangerType} from '../actions/dom-changes.action';

export interface DomChangerInterface {
  data: string;
  payload?: any;
}
const DomChangerInitialState: DomChangerInterface = {
  data: ''
};
export function domChanger(state: DomChangerInterface = DomChangerInitialState, action: DOMActions.Actions) {
  if (action.type === DomChangerType.headerTitle) {
    return {...state, ...action.payload};
  } else {
    return state;
  }
}
