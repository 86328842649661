import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiReqHandlersService} from '../app-services/api-req-handlers.service';
import {TokenService} from '../app-services/token.service';
import {API_URLS} from '../../utilities/app-urls';
import { catchError } from 'rxjs/operators';
import { NotificationService } from '../app-services/notification.service';
import { EncryptionService } from '../app-services/encryption.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private notificationService: NotificationService,
    private readonly _apiService: ApiReqHandlersService,
    private _token: TokenService, private _http: HttpClient) {
  }

  /**
   *
   * @param userObject
   */
  public setAuthUserToken(userObject): void {
    this._token.setAuthUser(userObject);
  }

  /**
   * Checks if type is logged in
   * @returns {boolean}
   */
  public isLoggedIn(): boolean {
    return this._token.isTokenLogged();
  }

  /**
   *
   * @param payload
   */
  public login(payload) {


    return this._apiService.post(`${API_URLS.accountService.login}`,
      {
        ...payload,
        ...{
          password: EncryptionService.enCodeBase64(payload.password)
        }
      })
  }

 public requestPhoneOTP(payload) {
   return this._apiService.get(`${API_URLS.accountService.requestOTP}?username=${payload}`)
     .pipe(catchError(async (resError) => {
        this.notificationService.showError(resError.error.resp_description);
      }));;
  }

  public sendPhoneOTP(payload, username) {
    return this._apiService.get(`${API_URLS.accountService.sendOTP}?code=${payload}&username=${username}`)
      .pipe(catchError(async (resError) => {
        this.notificationService.showError(resError.error.resp_description);
      }));;
  }

  public requestLoginOTP(payload) {
    return this._apiService.get(`${API_URLS.accountService.requestLoginOTP}?username=${payload}`).pipe(catchError(async (resError) => {
        this.notificationService.showError(resError.error.resp_description);
      }));
  }

  public sendLoginOTP(payload, username) {
    return this._apiService.get(`${API_URLS.accountService.sendLoginOTP}?code=${payload}&username=${username}`)
      .pipe(catchError(async (resError) => {
        this.notificationService.showError(resError.error.resp_description);
      }));
  }

  /**
   * @description use to log type out
   * @returns {Observable<any>}
   */
  public logout() {
    // return this._apiService.post(`${LOGOUT_URL}`);
  }

  /**
   *
   * @param payload
   */
  public updatePassword(payload) {
    return this._apiService.post(`${API_URLS.accountService.passwordReset}/reset`, payload);
  }

  /**
   *
   * @param payload
   */
  public forgetPassword(payload: any) {
    return this._apiService.post(`${API_URLS.accountService.passwordReset}/send-email`, payload);
  }

  /**
   * @param payload
   */
  public changePassword(payload: object) {
    return this._apiService.post(`${API_URLS.accountService.account}/change-password`, payload);
  }
}
