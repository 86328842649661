import { Config } from '../../utilities/config';
import { isFile } from 'src/app/shared/utilities/helpers';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ApiReqHandlersService extends Config {
    constructor(http) {
        super();
        this.http = http;
    }
    /**
     * post
     * @param path
     * @param data
     * @returns Observable
     */
    post(path, data, pin, headers) {
        this.headers = { headers: this.setHeaders(pin, headers) };
        const url = `${ApiReqHandlersService.BASE_URL}${path}`;
        return this.http.post(url, data || {}, this.headers);
    }
    /**
     *
     * @param {string} path
     * @returns {Observable<any>}
     */
    get(path, headers) {
        this.headers = { headers: this.setHeaders(null, headers) };
        const url = `${ApiReqHandlersService.BASE_URL}${path}`;
        return this.http.get(url, this.headers);
    }
    /**
     *
     * @param {string} path
     * @param data
     * @returns {Observable<any>}
     */
    put(path, data) {
        this.headers = { headers: this.setHeaders() };
        const url = `${ApiReqHandlersService.BASE_URL}${path}`;
        return this.http.put(url, data, this.headers);
    }
    /**
     *
     * @param {string} path
     * @param data
     * @returns {Observable<any>}
     */
    patch(path, data) {
        this.headers = { headers: this.setHeaders() };
        const url = `${ApiReqHandlersService.BASE_URL}${path}`;
        return this.http.patch(url, data, this.headers);
    }
    /**
     *
     * @param path
     * @param data
     */
    delete(path, data) {
        this.headers = { headers: this.setHeaders() };
        const url = `${ApiReqHandlersService.BASE_URL}${path}`;
        return this.http.delete(url, this.headers);
    }
    /**
     * @param urlLink
     * @param formFile
     * @param file_key
     * @param data
     * @param method
     */
    postFile(urlLink, formFile, file_key = "file", data, method, formKeys) {
        const header = this.setHeaders("multipart/form-data");
        this.headers = { headers: header };
        const urlPath = urlLink;
        var formData = new FormData();
        let file = null;
        if (formFile && formFile.files[0]) {
            file = formFile.files[0];
            formData.append(file_key, file, file.name);
        }
        if (data !== null) {
            Object.keys(data).forEach((e) => {
                if (!isFile(data[e])) {
                    formData.append(e, String(data[e]));
                }
            });
        }
        //  formData.forEach((value, key) => {
        //    console.log("Form KEY AND VALUE .... ", key + " " + value);
        //  });
        // // formData.append("body", data);
        // // return;
        const url = `${ApiReqHandlersService.BASE_URL}${urlPath}`;
        return this.http.post(url, formData);
    }
    /**
     *
     * @param data
     * @param formFiles
     * @param urlLink
     * @param file_key
     */
    postMultipleFile(data, formFiles, urlLink, file_key = "file") {
        const header = this.setHeaders();
        header.delete("Content-Type");
        this.headers = { headers: header };
        const path = $.param(data);
        const urlPath = Object.keys(data).length > 0 ? `${urlLink}?${path}` : urlLink;
        const formData = new FormData();
        let file = null;
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < formFiles.length; i++) {
            if (formFiles[i]) {
                file = formFiles[i];
                formData.append(file_key, file, file.name);
            }
            formData.append("body", data);
        }
        const url = `${ApiReqHandlersService.BASE_URL}${urlPath}`;
        return this.http.post(url, formData || {}, this.headers);
    }
}
ApiReqHandlersService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiReqHandlersService_Factory() { return new ApiReqHandlersService(i0.ɵɵinject(i1.HttpClient)); }, token: ApiReqHandlersService, providedIn: "root" });
