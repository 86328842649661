/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "@angular/common";
import * as i3 from "./login-otp.component";
import * as i4 from "@angular/router";
import * as i5 from "../../../../shared/services/app-services/notification.service";
import * as i6 from "../../../../shared/services/api-services/auth.service";
import * as i7 from "../../../../shared/services/app-services/token.service";
var styles_LoginOtpComponent = ["form[_ngcontent-%COMP%]   input[_ngcontent-%COMP%]{\n            display:inline-block;\n            width:45px;\n            height:45px;\n            text-align:center;\n            margin:5px\n          }"];
var RenderType_LoginOtpComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_LoginOtpComponent, data: {} });
export { RenderType_LoginOtpComponent as RenderType_LoginOtpComponent };
function View_LoginOtpComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["formRow", 1]], null, 7, "input", [["class", "form-control"], ["maxlength", "1"], ["type", "text"]], [[1, "maxlength", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "keyup"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 2)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 2)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("keyup" === en)) {
        var pd_4 = (_co.keyUpEvent($event, _v.context.index) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 16384, null, 0, i1.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i1.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵdid(3, 540672, null, 0, i1.MaxLengthValidator, [], { maxlength: [0, "maxlength"] }, null), i0.ɵprd(1024, null, i1.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i1.MaxLengthValidator]), i0.ɵprd(1024, null, i1.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i1.DefaultValueAccessor]), i0.ɵdid(6, 671744, null, 0, i1.FormControlName, [[3, i1.ControlContainer], [6, i1.NG_VALIDATORS], [8, null], [6, i1.NG_VALUE_ACCESSOR], [2, i1.ɵangular_packages_forms_forms_q]], { name: [0, "name"] }, null), i0.ɵprd(2048, null, i1.NgControl, null, [i1.FormControlName]), i0.ɵdid(8, 16384, null, 0, i1.NgControlStatus, [[4, i1.NgControl]], null, null)], function (_ck, _v) { var currVal_8 = "1"; _ck(_v, 3, 0, currVal_8); var currVal_9 = i0.ɵinlineInterpolate(1, "", _v.context.$implicit, ""); _ck(_v, 6, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = (i0.ɵnov(_v, 3).maxlength ? i0.ɵnov(_v, 3).maxlength : null); var currVal_1 = i0.ɵnov(_v, 8).ngClassUntouched; var currVal_2 = i0.ɵnov(_v, 8).ngClassTouched; var currVal_3 = i0.ɵnov(_v, 8).ngClassPristine; var currVal_4 = i0.ɵnov(_v, 8).ngClassDirty; var currVal_5 = i0.ɵnov(_v, 8).ngClassValid; var currVal_6 = i0.ɵnov(_v, 8).ngClassInvalid; var currVal_7 = i0.ɵnov(_v, 8).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_LoginOtpComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { rows: 1 }), (_l()(), i0.ɵeld(1, 0, null, null, 10, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 9, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngSubmit"], [null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 4).onReset() !== false);
        ad = (pd_1 && ad);
    } if (("ngSubmit" === en)) {
        var pd_2 = (_co.submit() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 16384, null, 0, i1.ɵangular_packages_forms_forms_z, [], null, null), i0.ɵdid(4, 540672, null, 0, i1.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, { ngSubmit: "ngSubmit" }), i0.ɵprd(2048, null, i1.ControlContainer, null, [i1.FormGroupDirective]), i0.ɵdid(6, 16384, null, 0, i1.NgControlStatusGroup, [[4, i1.ControlContainer]], null, null), (_l()(), i0.ɵeld(7, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LoginOtpComponent_1)), i0.ɵdid(9, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(10, 0, null, null, 1, "button", [["class", "btn btn-block  btn-warning "], ["type", "submit"]], [[8, "disabled", 0]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Submit"]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.form; _ck(_v, 4, 0, currVal_7); var currVal_8 = _co.formInput; _ck(_v, 9, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 6).ngClassValid; var currVal_5 = i0.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 6).ngClassPending; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_9 = !_co.form.valid; _ck(_v, 10, 0, currVal_9); }); }
export function View_LoginOtpComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-login-otp", [], null, null, null, View_LoginOtpComponent_0, RenderType_LoginOtpComponent)), i0.ɵdid(1, 114688, null, 0, i3.LoginOtpComponent, [i4.Router, i5.NotificationService, i6.AuthService, i7.TokenService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginOtpComponentNgFactory = i0.ɵccf("app-login-otp", i3.LoginOtpComponent, View_LoginOtpComponent_Host_0, { userData: "userData", phoneOtp: "phoneOtp", username: "username", userPhone: "userPhone" }, { submitOTPValue: "submitOTPValue" }, []);
export { LoginOtpComponentNgFactory as LoginOtpComponentNgFactory };
