export const API_URLS = {
  accountService: {
    sendOTP: "core/account/confirm-telephone",
    requestOTP: "core/account/phone-number-confirmation-sms",
    requestLoginOTP: "core/account/device/connect",
    sendLoginOTP: "core/account/device/confirm",
    login: "core/account/login",
    logout: "core/logout",
    account: "core/account",
    outlet: "core/outlets",
    accounts: "core/account",
    passwordReset: "core/account-password-reset",
  },

  adminService: {
    role: "core/admin/role",
    auditTrails: "core/admin/audit-trail",
    apiLogs: "core/admin/api-log",
    DeviceAcquisition: "core/admin/device-acquisition",
  },

  userService: {
    user: "core/admin/user-management",
    category: "core/admin/category",
    deviceOwner: "core/admin/device-owner",
    admin: "core/admin",
    blacklist: "core/admin/user-management",
    outlet: "core/outlets",
    policy: "core/api/Policy",
    approval: "core/auth/approval-authorization",
  },
  billerService: {
    billers: "bills/billers",
    category: "bills/biller_categories",
    billerByServiceType: "bills/billers-category",
    admin: "bills/admin",
    agent: "bills/agent",
    transaction_Services: "transactions/v1",
  },
  kycService: {
    newKyc: "kyc-updater",
    kyc: "kyc/admin",
    banks: "kyc/public/banks",
    partnerType: "core/admin/kyc-partner-type",
    states: "kyc/states",
    accountConfig: "core/admin/allowed-account-field-reuse",
  },

  settingService: {
    limit: {
      periods: "core/admin/limit-period",
      types: "core/admin/limit-type",
      transaction: "core/admin/transaction-limit",
      transfer: "core/admin/transfer-limit",
    },
  },

  transactionService: {
    transactions: "transactions/admin/transactions",
    transactionType: "transactions/admin/transaction_types",
    limitTransactionType: "transactions/admin/limit_transaction_types",
    transactionService: "transactions/admin/transaction_services",
    channels: "transactions/admin/channels",
    commissionsSummary: "transactions/admin/service/commissions/summary",
    commissionReport: "transactions/admin/service/transactions/report",
    upgradeTransaction: "transactions/admin",
    onDemandReport: "utility/admin",
    onDemandReportAgent: "utility/agent",
    setting: "transactions/admin/settings/categories",
    deviceOwner: "transactions/admin/device-owner",
    merchantTransactions: "transactions/v1",
    paymentMethod: "transactions",
    updateSetting: "transactions/admin/settings",
    receipt: "transactions/admin/card-transactions",
    transferDetails: "utility/admin/techsupport/pending/fiap-report"
  },

  deviceOwner: {
    categories: "core/account/beneficiary/categories",
    deviceTransaction: "transactions/v1/device-owner/device/transactions",
    settulmentReport: "transactions/v1/device-owner/settlements",
    merchants: "core/account/beneficiary/users",
    deviceOwnersAdmin: "core/account/beneficiary/admin-user",
    createAdminUser: "core/account/beneficiary/create-admin-user",
    devices: "card-pay/device-owner/devices",
    deviceStat: "card-pay/device-owner/stats",
    deviceOwnerTransaction: "transactions/v1/device-owner/transactions",
    dashboardStat: "transactions/v1/device-owner/dashboard/stats",
    transactionsSummary:
      "transactions/v1/device-owner/dashboard/transaction-types",
    totalDashboardTransaction: "transactions/v1/device-owner/dashboard/cards",
    deviceFilter: "card-pay/device-owner/filter",
  },
  walletService: {
    wallet: "wallet/admin/admin/wallet",
    commissions: "commission/admin",
    beneficiaries: "wallet/admin/beneficiary",
    fee: "wallet/admin/fee-profiles",
    feeTypes: "wallet/admin/fee-types",
    sharingFormula: {
      commissions: "wallet/admin/commission-profile",
      fee: "wallet/admin/fee-sharing-formulas",
    },
    reports: "wallet/admin/wallhistory-logs",
  },

  commissionService: {
    commissions: "commission/admin/commissions",
    baseLine: "commission/admin/commission_baselines",
    products: "commissionsdb/products",
    stakeholders: "commissionsdb/stakeholders",
    profiles: "commissionsdb/profiles",
    profileProducts: "commissionsdb/profile_products",
    stakes: "commissionsdb/stakes",
  },

  cardAndAccountService: {
    cardPay: "card-pay/admin",
    virtualAccounts: "vaccount/admin/account/get-all-virtual-accounts",
    virtualAccountDetail: "vaccount/admin/account/filter-account-numbers",
    virtualAccountProviders: "vaccount/admin/account/get-all-account-providers",
    virtualAccountProvidersBalance:
      "vaccount/admin/account/account-provider/balance",
    virtualAccountProvidersStatement:
      "vaccount/admin/account/account-provider/statement",
    creditAccount: "vaccount/admin/account/account-provider/transfer-fund",
    virtualAccountsBalance: "vaccount/admin/reports/balance",
    fundingcardBalance: "account/admin/merchant/fundingcardbalance",
    withdrawalcardBalance: "account/admin/merchant/withdrawalcardbalance",
    cardBaseUrl: "account/admin",
    virtualCardList: 'account/admin/virtual-cards',
    cardDetails: "account/admin/allcarddetails",
    exchangeRate: "account/admin/exchange-rate",
    virtualCardProviderBase: "account/admin/card-provider",
    exchangeRateHistory: "account/admin/exchange-rate-history",
    batchRate: "account/admin/batch-rate",
    cardTransactions: "account/admin/merchanthistory",
    cardTransfers: "account/admin/card-transfer",
    reports: "vaccount/admin/reports",
    sourceBank: "vaccount/transfer/source-banks",
    cardStatus: "account/admin/cardstatus",
    cardInfo: "account/card/info",
    cardHistoryReport: "account/transfer/user-cardhistory",
    blacklistedAccount: "vaccount/admin/account-blacklist",
  },

  deviceService: {
    bankList: "card-pay/banks",
    types: "card-pay/admin/device-type",
    serialNumber: "card-pay/admin/device-serial-number",
    userDevices: "card-pay/admin/user-devices",
    settlement: "transactions/admin/settlements",
    swap: "card-pay/admin/device-owner/migration",
  },

  miscService: {
    deviceVersion: "core/admin/mobile-device-update",
    serbianReport: "wallet/public/serbian/users/transactions",
    transactions: "transactions/admin",
    autoSweep: "auto-sweep/admin",
    nameEnq: "account/admin/nameenquiry",
    banks: "account/admin/banklist",
    bankList: "account/account/banklist",
    sweep: "auto-sweep/v1/sweep",
    generalnameEnq: "account/account/nameenquiry",
  },

  dashboardService: {
    dailySignUp: "core/admin/dashboard/daily-new-signup",
    totalBVNSignUp: "core/admin/dashboard/total-bvn-signup",
    totalPartnerByType: "core/admin/dashboard/total-partner-by-type",
    totalReferedPartner: "core/admin/dashboard/total-referred-partner",
    totalAccountWithTID: "core/admin/dashboard/total-account-with-tid",
    transactionSummary: "transactions/v1/upgrade/agent",
  },

  templateService: {
    template: "core/account/templates",
    logs_list: "core/account/Notifications/get-all-notifications",
  },
  notificationService: {
    sendGenricNotification: `core/account/Notifications/send-generic-notification`,
    getAllNotifications: `core/account/Notifications/get-all-notifications`,
    notificationBaseUs: 'notification/admin/custom-messaging-platform'
  },
  addressService: {
    updateAddressStatus: `core/address`,
  },
};
