import {Action} from '@ngrx/store';


export enum ResponseData {
  responseParser = '[RESPONSE] add',
  responseRemover = '[RESPONSE] remove'
}

export class ResponseDataAction implements Action {
  readonly type = ResponseData.responseParser;
  constructor(public payload) {
  }
}

export class ResponseDataRemover implements Action {
  readonly type = ResponseData.responseRemover;
  constructor(public payload) {
  }
}

export type Actions = ResponseDataAction | ResponseDataRemover;
