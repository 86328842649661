/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "@angular/common";
import * as i3 from "./phone-otp.component";
import * as i4 from "../../../../shared/services/app-services/notification.service";
import * as i5 from "@angular/router";
import * as i6 from "ngx-bootstrap/modal";
import * as i7 from "../../../../shared/services/api-services/auth.service";
var styles_OtpComponent = ["form[_ngcontent-%COMP%]   input[_ngcontent-%COMP%] {\n        display: inline-block;\n        width: 50px;\n        height: 50px;\n        text-align: center;\n        margin: 5px;\n      }\n      img[_ngcontent-%COMP%] {\n        height: 150px;\n        width: 150px;\n      }"];
var RenderType_OtpComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_OtpComponent, data: {} });
export { RenderType_OtpComponent as RenderType_OtpComponent };
function View_OtpComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "form-group col-sm-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["formRow", 1]], null, 7, "input", [["class", "form-control"], ["maxlength", "1"], ["type", "text"]], [[1, "maxlength", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "keyup"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 2)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 2)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("keyup" === en)) {
        var pd_4 = (_co.keyUpEvent($event, _v.context.index) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 16384, null, 0, i1.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i1.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵdid(3, 540672, null, 0, i1.MaxLengthValidator, [], { maxlength: [0, "maxlength"] }, null), i0.ɵprd(1024, null, i1.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i1.MaxLengthValidator]), i0.ɵprd(1024, null, i1.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i1.DefaultValueAccessor]), i0.ɵdid(6, 671744, null, 0, i1.FormControlName, [[3, i1.ControlContainer], [6, i1.NG_VALIDATORS], [8, null], [6, i1.NG_VALUE_ACCESSOR], [2, i1.ɵangular_packages_forms_forms_q]], { name: [0, "name"] }, null), i0.ɵprd(2048, null, i1.NgControl, null, [i1.FormControlName]), i0.ɵdid(8, 16384, null, 0, i1.NgControlStatus, [[4, i1.NgControl]], null, null)], function (_ck, _v) { var currVal_8 = "1"; _ck(_v, 3, 0, currVal_8); var currVal_9 = i0.ɵinlineInterpolate(1, "", _v.context.$implicit, ""); _ck(_v, 6, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = (i0.ɵnov(_v, 3).maxlength ? i0.ɵnov(_v, 3).maxlength : null); var currVal_1 = i0.ɵnov(_v, 8).ngClassUntouched; var currVal_2 = i0.ɵnov(_v, 8).ngClassTouched; var currVal_3 = i0.ɵnov(_v, 8).ngClassPristine; var currVal_4 = i0.ɵnov(_v, 8).ngClassDirty; var currVal_5 = i0.ɵnov(_v, 8).ngClassValid; var currVal_6 = i0.ɵnov(_v, 8).ngClassInvalid; var currVal_7 = i0.ɵnov(_v, 8).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_OtpComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { rows: 1 }), (_l()(), i0.ɵeld(1, 0, null, null, 17, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "img", [["alt", "whast is this "], ["class", "img-fluid"], ["src", "assets/images/Enter-OTP-cuate.svg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Enter the otp number sent to your phone."])), (_l()(), i0.ɵeld(5, 0, null, null, 9, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngSubmit"], [null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 7).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 7).onReset() !== false);
        ad = (pd_1 && ad);
    } if (("ngSubmit" === en)) {
        var pd_2 = (_co.submit() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(6, 16384, null, 0, i1.ɵangular_packages_forms_forms_z, [], null, null), i0.ɵdid(7, 540672, null, 0, i1.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, { ngSubmit: "ngSubmit" }), i0.ɵprd(2048, null, i1.ControlContainer, null, [i1.FormGroupDirective]), i0.ɵdid(9, 16384, null, 0, i1.NgControlStatusGroup, [[4, i1.ControlContainer]], null, null), (_l()(), i0.ɵeld(10, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_OtpComponent_1)), i0.ɵdid(12, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(13, 0, null, null, 1, "button", [["class", "btn btn-block  btn-warning "], ["type", "submit"]], [[8, "disabled", 0]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Submit "])), (_l()(), i0.ɵeld(15, 0, null, null, 3, "button", [["class", "btn btn-primary mt-1 position-relative"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.retry() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" Retry otp "])), (_l()(), i0.ɵeld(17, 0, null, null, 1, "span", [["class", "position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"]], null, null, null, null, null)), (_l()(), i0.ɵted(18, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.form; _ck(_v, 7, 0, currVal_7); var currVal_8 = _co.formInput; _ck(_v, 12, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 9).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 9).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 9).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 9).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 9).ngClassValid; var currVal_5 = i0.ɵnov(_v, 9).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 9).ngClassPending; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_9 = !_co.form.valid; _ck(_v, 13, 0, currVal_9); var currVal_10 = _co.retryNow; _ck(_v, 15, 0, currVal_10); var currVal_11 = _co.timeCounter; _ck(_v, 18, 0, currVal_11); }); }
export function View_OtpComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-otp-phone", [], null, null, null, View_OtpComponent_0, RenderType_OtpComponent)), i0.ɵdid(1, 114688, null, 0, i3.OtpComponent, [i4.NotificationService, i5.Router, i6.BsModalService, i4.NotificationService, i7.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OtpComponentNgFactory = i0.ɵccf("app-otp-phone", i3.OtpComponent, View_OtpComponent_Host_0, { modalPhone: "modalPhone", component: "component", phoneOtp: "phoneOtp", username: "username", userData: "userData", userPhone: "userPhone" }, { submitOTPValue: "submitOTPValue" }, []);
export { OtpComponentNgFactory as OtpComponentNgFactory };
