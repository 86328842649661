
    <div class="container-fluid">
      <img
        src="assets/images/Enter-OTP-cuate.svg"
        class="img-fluid"
        alt="whast is this "
      />
      <p>Enter the otp number sent to your phone.</p>
      <form (ngSubmit)="submit()" [formGroup]="form">
        <div class="row">
          <div
            class="form-group col-sm-2"
            *ngFor="let input of formInput; index as i"
          >
            <input
              #formRow
              type="text"
              formControlName="{{ input }}"
              class="form-control"
              maxlength="1"
              (keyup)="keyUpEvent($event, i)"
            />
          </div>
        </div>
        <button
          type="submit"
          [disabled]="!form.valid"
          class="btn btn-block  btn-warning "
        >
          Submit
        </button>
      </form>

      <button
        [disabled] = 'retryNow'
        type="button"
        (click)="retry()"
        class="btn btn-primary mt-1 position-relative"
      >
        Retry otp
        <span
          class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
        >
          {{ timeCounter }}
        </span>
      </button>
    </div>
  