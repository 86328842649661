import * as CryptoJS from 'crypto-js';
import { CUSTOM_CONSTANTS } from '../../utilities/config';
import * as i0 from "@angular/core";
export class EncryptionService {
    constructor() { }
    /**
     * This is used to encrypt data to be saved to cache in front
     * @param data
     * @returns {any|PromiseLike<ArrayBuffer>}
     */
    static encrypt(data) {
        if (!data) {
            return null;
        }
        return CryptoJS.AES.encrypt(JSON.stringify(data), EncryptionService.KEY);
    }
    /**
     * This is used to decrypt data encrypted in cache for usage in front.
     * @param data
     * @returns {any}
     */
    static decrypt(data) {
        if (!data) {
            return null;
        }
        const decryptData = CryptoJS.AES.decrypt(data.toString(), EncryptionService.KEY);
        return JSON.parse(decryptData.toString(CryptoJS.enc.Utf8));
    }
    /**
     * used to encrypt data to be sent to api resource
     * @param data
     * @returns {string}
     */
    static getEncrypted(data) {
        if (!data) {
            return null;
        }
        return CryptoJS.AES.encrypt(JSON.stringify(data), EncryptionService.KEY, { format: EncryptionService.CryptoJSAesJson }).toString();
    }
    /**
     * Used to decrypt encrypted data sent from api resource
     * @param data
     * @returns {any}
     */
    static decryptEncrypted(data) {
        if (!data) {
            return null;
        }
        const decryptData = CryptoJS.AES.decrypt(data.toString(), EncryptionService.KEY, { format: EncryptionService.CryptoJSAesJson });
        return JSON.parse(decryptData.toString(CryptoJS.enc.Utf8));
    }
    /**
     * This is used to decode JSON WEB TOKEN
     * @param token
     * @returns {any}
     */
    static jwtDecrypt(token) {
        if (!token) {
            return null;
        }
        if (typeof (token) === 'object') {
            return token;
        }
        // console.log('thisToken =', token);
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        //  // console.log('thisTokenDe =', JSON.parse(window.atob(base64)));
        return JSON.parse(window.atob(base64));
    }
    /**
     *
     * @param data
     */
    static enCodeBase64(data) {
        const word = CryptoJS.enc.Utf8.parse(data);
        return CryptoJS.enc.Base64.stringify(word);
    }
    /**
     *
     * @param data
     */
    static deCodeBase64(data) {
        const encodedWord = CryptoJS.enc.Base64.parse(data);
        return CryptoJS.enc.Utf8.stringify(encodedWord);
    }
}
EncryptionService.KEY = CUSTOM_CONSTANTS.ENCRYPTION_KEY;
EncryptionService.CryptoJSAesJson = {
    stringify: (cipherParams) => {
        const j = { ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64), s: null, iv: null };
        if (cipherParams.iv) {
            j.iv = cipherParams.iv.toString();
        }
        if (cipherParams.salt) {
            j.s = cipherParams.salt.toString();
        }
        return JSON.stringify(j);
    },
    parse: (jsonStr) => {
        const j = JSON.parse(jsonStr);
        const cipherParams = CryptoJS.lib.CipherParams.create({ ciphertext: CryptoJS.enc.Base64.parse(j.ct) });
        if (j.iv) {
            cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv);
        }
        if (j.s) {
            cipherParams.salt = CryptoJS.enc.Hex.parse(j.s);
        }
        return cipherParams;
    }
};
EncryptionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EncryptionService_Factory() { return new EncryptionService(); }, token: EncryptionService, providedIn: "root" });
