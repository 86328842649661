import {Action} from '@ngrx/store';

export enum searchAction {
  GET_RESULT = '[GET] search',
  NEXT_RESULT = '[POST] search',
  RESET_ACTION = '[RESET] search',
  DOWNLOAD = '[DOWNLOAD] search'
}

export class PageFilterResult implements Action {
  readonly type = searchAction.GET_RESULT;
  constructor(public payload: any) {
  }
}

export class PageFilterNextResult implements Action {
  readonly type = searchAction.NEXT_RESULT;
  constructor(public payload: any) {
  }
}

export class PageFilterResetResult implements Action {
  readonly type = searchAction.RESET_ACTION;
  constructor(public payload: any) {
  }
}

export class PageExportResults implements Action {
  readonly type = searchAction.DOWNLOAD;
  constructor(public payload: any) {
  }
}

export type Actions = PageFilterResult | PageFilterNextResult | PageFilterResetResult | PageExportResults;
