import {Action} from '@ngrx/store';

export enum DomChangerType {
  headerTitle = '[TITLE] headerTitle'
}


export class ChangeTitle implements Action {
  readonly type = DomChangerType.headerTitle;
  constructor(public payload) {}
}

export type Actions = ChangeTitle;
