import * as FilterActions from './../actions/page-filter.action';
import { searchAction } from 'src/app/store/actions/page-filter.action';

export interface PageFilterReducerInterface {
  action: '';
  payload: any;
  searchResult?: boolean;
}

const initialState: PageFilterReducerInterface = {
  action: '',
  payload: '',
  searchResult: false
};

export function pageFilterReducers(state: PageFilterReducerInterface = initialState, action: FilterActions.Actions) {
  // console.log(state);
  switch (action.type) {
    case searchAction.GET_RESULT:
      return { ...state, ...action.payload };
    case searchAction.NEXT_RESULT:
      return { ...state, ...action.payload };
    case searchAction.RESET_ACTION:
      return { ...state, ...action.payload };
    case searchAction.DOWNLOAD:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
