
<div class="no-auth-container">
  <div class="no-auth-form-wrapper">
    <div class="form-holder">
      <div class="p-3 text-center form-top mb-3">

        <div class="logo">
          <img src="assets/images/baxibox-loader.png" alt="">
        </div>
        <div class="title">
          <h3 [innerHTML]="state.pageTitle"></h3>
        </div>
      </div>

      <main class="forms">
        <ng-container *ngIf="state.formDOM === 'LOGIN'">
          <form class="animated fadeInUp" [formGroup]="loginFormGroup" (ngSubmit)="submitForm(phoneOtp, loginOTP)">
            <div class="form-group mb-3">
              <input type="text" placeholder="Enter Username" formControlName="username" class="no-auth-input" autocomplete="true"
                     [class.error]="loginFormGroup.get('username').invalid && loginFormGroup.get('username').touched">
              <span class="no-auth-input-icon">
              <i class="dripicons-user"></i>
            </span>
              <div class="form-error k-form-error"
                   *ngIf="loginFormGroup.get('username').invalid && (loginFormGroup.get('username').touched || loginFormGroup.get('username').dirty)">
                <span *ngIf="loginFormGroup.get('username').errors.required || loginFormGroup.get('username').errors.whitespace"><b>*</b> Username is required</span>
              </div>
            </div>

            <div class="form-group">
              <input type="password" formControlName="password" placeholder="Password" class="no-auth-input" id="lp-input"  autocomplete="true"
                     [class.error]="loginFormGroup.get('password').invalid && loginFormGroup.get('password').touched">
              <span class="no-auth-input-icon">
              <i class="dripicons-lock"></i>
            </span>
              <span class="input-preview-password" (click)="previewPassword('lp-input')">
              <i class="dripicons-preview"></i>
            </span>
              <div class="form-error k-form-error" *ngIf="loginFormGroup.get('password').invalid && (loginFormGroup.get('password').touched || loginFormGroup.get('password').dirty)">
                <span><b>*</b> Password is required</span>
              </div>
            </div>
            <div class="f-password-link">
              <a (click)="switchForms('FORGOT_PASSWORD')">Forgot Password?</a>
            </div>

            <div class="form-group mt-4">
              <button type="submit" class="no-auth-btn" [disabled]="state.loader">
                <span *ngIf="!state.loader">Login</span>
                <svg *ngIf="state.loader" xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.0" width="40px" height="30px" viewBox="0 0 128 128" xml:space="preserve"><g><path d="M59.6 0h8v40h-8V0z" fill="#ffffff" fill-opacity="1"/><path d="M59.6 0h8v40h-8V0z" fill="#fff" fill-opacity="0.2" transform="rotate(30 64 64)"/><path d="M59.6 0h8v40h-8V0z" fill="#ffffff" fill-opacity="0.2" transform="rotate(60 64 64)"/><path d="M59.6 0h8v40h-8V0z" fill="#ffffff" fill-opacity="0.2" transform="rotate(90 64 64)"/><path d="M59.6 0h8v40h-8V0z" fill="#ffffff" fill-opacity="0.2" transform="rotate(120 64 64)"/><path d="M59.6 0h8v40h-8V0z" fill="#fff" fill-opacity="0.3" transform="rotate(150 64 64)"/><path d="M59.6 0h8v40h-8V0z" fill="#fff" fill-opacity="0.4" transform="rotate(180 64 64)"/><path d="M59.6 0h8v40h-8V0z" fill="#fff" fill-opacity="0.5" transform="rotate(210 64 64)"/><path d="M59.6 0h8v40h-8V0z" fill="#fff" fill-opacity="0.6" transform="rotate(240 64 64)"/><path d="M59.6 0h8v40h-8V0z" fill="#fff" fill-opacity="0.7" transform="rotate(270 64 64)"/><path d="M59.6 0h8v40h-8V0z" fill="#fff" fill-opacity="0.8" transform="rotate(300 64 64)"/><path d="M59.6 0h8v40h-8V0z" fill="#fff" fill-opacity="0.9" transform="rotate(330 64 64)"/><animateTransform attributeName="transform" type="rotate" values="0 64 64;30 64 64;60 64 64;90 64 64;120 64 64;150 64 64;180 64 64;210 64 64;240 64 64;270 64 64;300 64 64;330 64 64" calcMode="discrete" dur="840ms" repeatCount="indefinite"></animateTransform></g></svg>
              </button>
            </div>
          </form>
        </ng-container>

        <ng-template [ngIf]="state.formDOM === 'FORGOT_PASSWORD'">
          <app-forgot-password (switchDom)="switchForms('LOGIN')"></app-forgot-password>
        </ng-template>

        <ng-template [ngIf]="state.formDOM === 'CHANGE_PASSWORD'">
          <app-change-password></app-change-password>
        </ng-template>
      </main>

    </div>
  </div>
</div>
  <ng-template #phoneOtp>

    <div class="modal-body text-center">
      <app-otp-phone [userPhone]='userPhone' [modalPhone] ='modalPhone' [phoneOtp] = 'phoneOtp'
       [username]='username'  ></app-otp-phone>
    </div>
  </ng-template>
   <ng-template #loginOTP >
      <div class="modal-body text-center">
        <h4>OTP</h4>
        <app-login-otp  [userData] = 'loginFormGroup.value'></app-login-otp>
      </div>
   </ng-template>
