import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChildren,
} from "@angular/core";
import { FormControl, Validators, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { AuthService } from "src/app/shared/services/api-services/auth.service";
import { NotificationService } from "src/app/shared/services/app-services/notification.service";

@Component({
  selector: "app-otp-phone",
  template: `
    <div class="container-fluid">
      <img
        src="assets/images/Enter-OTP-cuate.svg"
        class="img-fluid"
        alt="whast is this "
      />
      <p>Enter the otp number sent to your phone.</p>
      <form (ngSubmit)="submit()" [formGroup]="form">
        <div class="row">
          <div
            class="form-group col-sm-2"
            *ngFor="let input of formInput; index as i"
          >
            <input
              #formRow
              type="text"
              formControlName="{{ input }}"
              class="form-control"
              maxlength="1"
              (keyup)="keyUpEvent($event, i)"
            />
          </div>
        </div>
        <button
          type="submit"
          [disabled]="!form.valid"
          class="btn btn-block  btn-warning "
        >
          Submit
        </button>
      </form>

      <button
        [disabled] = 'retryNow'
        type="button"
        (click)="retry()"
        class="btn btn-primary mt-1 position-relative"
      >
        Retry otp
        <span
          class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
        >
          {{ timeCounter }}
        </span>
      </button>
    </div>
  `,
  styles: [
    `
      form input {
        display: inline-block;
        width: 50px;
        height: 50px;
        text-align: center;
        margin: 5px;
      }
      img {
        height: 150px;
        width: 150px;
      }
    `,
  ],
})
export class OtpComponent implements OnInit {
  timeCounter: number = 1;
  retryNow: boolean = true;
  form;
  @Input() modalPhone: any;
  @Input() component: any;
  @Input() phoneOtp: any;
  @Input() username: any;
  @Input() userData: any;
  @Input() userPhone: any;
  @Output("submitOTPValue") submitOTPValue: EventEmitter<any> =
    new EventEmitter<any>();
  formInput = ["int1", "int2", "int3", "int4", "int5", "int6"];
  @ViewChildren("formRow") rows: any;
  retry() {
    this._authService.requestPhoneOTP(this.username).subscribe(res => {
      if (res) {
        this._notificationService.showSuccess(res.resp_description)
      }
    });
  }
  modalRef: BsModalRef;
  constructor(
    private _notificationService: NotificationService,
    private _route: Router,
    private modalService: BsModalService,
    private notificationService: NotificationService,
    private _authService: AuthService
  ) {
    this.form = this.toFormGroup(this.formInput);
  }

  ngOnInit() {
    let x;
    let intervalID = setInterval(() => {
      this.timeCounter++;
      console.log(x++);
    }, 1000);
    setTimeout(() => {
      console.log("please log this");
      this.retryNow = false;
      clearInterval(intervalID);
    }, 10000);
  }

  toFormGroup(elements) {
    const group: any = {};
    elements.forEach((key) => {
      group[key] = new FormControl("", Validators.required);
    });
    return new FormGroup(group);
  }
  

  keyUpEvent(event, index) {
    let pos = index;
    if (event.keyCode === 8 && event.which === 8) {
      pos = index - 1;
    } else {
      pos = index + 1;
    }
    if (pos > -1 && pos < this.formInput.length) {
      this.rows._results[pos].nativeElement.focus();
    }
  }

  submit() {
    // value: T) => void, error ?: (error: any) => void, complete ?
    // this.modalPhone.hide();
    this.modalService.hide(this.phoneOtp);
    this.submitOTPValue.emit();
    let otpNumber = Object.values(this.form.value).join("");
    this._authService
      .sendPhoneOTP(otpNumber, this.username)
      .subscribe((result) => {
        if (result) {
          this.notificationService.showSuccess(result.resp_description);
          // this._route.navigate(['/device-owner/dashboard'])
        }
      });
  }
}
