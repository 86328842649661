import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SharedModule} from './shared/modules/shared.module';
import {NgProgressModule} from '@ngx-progressbar/core';
import {ToastrModule} from 'ngx-toastr';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {HttpInterceptorService} from './shared/services/app-services/http-interceptor.service';
import {AuthGuard} from './shared/guards/auth.guard';
import { LoginComponent } from './components/no-auth/login/login.component';
import {ChangePasswordComponent} from './components/no-auth/change-password/change-password.component';
import { ForgotPasswordComponent } from './components/no-auth/forgot-password/forgot-password.component';
import {StoreModule} from '@ngrx/store';
import {reducers} from './store/reducers';
import {AccessDeniedComponent} from './shared/components/access-denied/access-denied.component';
import {NotFoundComponent} from './shared/components/not-found/not-found.component';
import { AccountDeviceComponent } from './shared/components/users/account-device/account-device.component';
import { NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AccountNumberComponent } from './shared/components/users/account-number/account-number.component';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { AgentGuard } from './shared/guards/agent.guard';
import { EmbeddedComponents } from './shared/directives/dynamic-content-viewer';
import { OtpComponent } from './components/no-auth/login/otp/phone-otp.component';
import { LoginOtpComponent } from './components/no-auth/login/otp/login-otp.component';
import { ResetPasswordComponent } from './components/no-auth/reset-password/reset-password.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ChangePasswordComponent,
    ForgotPasswordComponent,
    AccessDeniedComponent,
    NotFoundComponent,
    AccountDeviceComponent,
    AccountNumberComponent,
    LoginOtpComponent,
    ResetPasswordComponent,
    OtpComponent,
    // VirtalCardHotFixComponent,
  ],
  imports: [
    ReactiveFormsModule,
    ChartsModule,
    NgbModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    NgProgressModule,
    HttpClientModule,
    ToastrModule.forRoot({
      closeButton: true,
      preventDuplicates: true,
      enableHtml: true,
    }),
    StoreModule.forRoot(reducers),
  ],
  providers: [
    ThemeService,
    HttpClientModule,
    AuthGuard,
    AgentGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
