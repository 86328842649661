import { Injectable } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { errorHelper } from '../../utilities/helpers';
import Swal from 'sweetalert2';

// sweetAlertNotification
export const SWEET_ALERT = (alert_title?, alert_text?, alert_type?, confBtn = 'OK', cancelBtn?: boolean) => {
  return Swal.fire({
    title: alert_title,
    html: alert_text,
    type: alert_type,
    showCancelButton: !!cancelBtn,
    reverseButtons: true,
    confirmButtonClass: 'btn btn-success',
    confirmButtonText: confBtn,
    cancelButtonClass: cancelBtn ? 'btn btn-danger' : '',
  });
};

/**
 * NotificationPosition
 */
export enum NotificationPosition {
  TOP_RIGHT = 'toast-top-right',
  TOP_LEFT = 'toast-top-left',
  BOTTOM_RIGHT = 'toast-bottom-right',
  BOTTON_LEFT = 'toast-bottom-left',
  TOP_CENTER = 'toast-top-center'
  // Other positions I would like
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private readonly DEFAULT_DELAY = 5000;

  // Configured alert noitification message
  individualConfig: Partial<IndividualConfig> = {
    positionClass: 'toast-bottom-right',
    progressBar: false,
    closeButton: true,
    onActivateTick: true,
    enableHtml: false,
  };

  constructor(private toastr: ToastrService) {
  }

  /**
   * @param message
   * @param title
   * @param timeOut
   * @param progressBar
   * @param positionClass
   */
  public showSuccess(message: string, title?: any, timeOut = this.DEFAULT_DELAY, progressBar?: boolean, positionClass = NotificationPosition.TOP_RIGHT): any {
    // const delay = this.manageTimeDelay(timeOut);
    return this.toastr.success(message, title, { timeOut, progressBar, positionClass, toastClass: 'animated fadeInDown' });
  }


  public showError(message: string, error?, title?: any, timeOut = this.DEFAULT_DELAY, positionClass = NotificationPosition.TOP_RIGHT): any {
    // const delay = this.manageTimeDelay(timeOut);
    const response = errorHelper(message, error);
    return this.toastr.error(response, title, { timeOut: timeOut === null ? this.DEFAULT_DELAY : timeOut, positionClass, toastClass: 'animated fadeInDown' });
  }

  /* *
  *
  * @param message
  * @param title
  * @param timeOut
  */
  public showWarning(message: string, title?: any, timeOut = this.DEFAULT_DELAY): any {
    // const delay = this.manageTimeDelay(timeOut);
    return this.toastr.warning(message, title, { timeOut, toastClass: 'animated fadeInDown' });
  }

  /**
   * @param message
   * @param title
   * @param timeOut
   */
  public showInfo(message: string, title?: any, timeOut = this.DEFAULT_DELAY): any {
    // const delay = this.manageTimeDelay(timeOut);
    return this.toastr.info(message, title, { timeOut, toastClass: 'animated fadeInDown' });
  }


  /**
   *
   * @param {string} message
   * @param title
   * @param {string} type
   */
  show(message: string, title, type: string) {
    this.toastr.show(
      message,                // message
      title,                     // title
      this.individualConfig,  // IndividualConfig or GlobalConfig
      type                    // 'toast-success', 'toast-error', 'toast-warning' or 'toast-info'
    );
  }
}
