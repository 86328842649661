import { errorHelper } from '../../utilities/helpers';
import Swal from 'sweetalert2';
import * as i0 from "@angular/core";
import * as i1 from "ngx-toastr";
// sweetAlertNotification
export const SWEET_ALERT = (alert_title, alert_text, alert_type, confBtn = 'OK', cancelBtn) => {
    return Swal.fire({
        title: alert_title,
        html: alert_text,
        type: alert_type,
        showCancelButton: !!cancelBtn,
        reverseButtons: true,
        confirmButtonClass: 'btn btn-success',
        confirmButtonText: confBtn,
        cancelButtonClass: cancelBtn ? 'btn btn-danger' : '',
    });
};
/**
 * NotificationPosition
 */
export var NotificationPosition;
(function (NotificationPosition) {
    NotificationPosition["TOP_RIGHT"] = "toast-top-right";
    NotificationPosition["TOP_LEFT"] = "toast-top-left";
    NotificationPosition["BOTTOM_RIGHT"] = "toast-bottom-right";
    NotificationPosition["BOTTON_LEFT"] = "toast-bottom-left";
    NotificationPosition["TOP_CENTER"] = "toast-top-center";
    // Other positions I would like
})(NotificationPosition || (NotificationPosition = {}));
export class NotificationService {
    constructor(toastr) {
        this.toastr = toastr;
        this.DEFAULT_DELAY = 5000;
        // Configured alert noitification message
        this.individualConfig = {
            positionClass: 'toast-bottom-right',
            progressBar: false,
            closeButton: true,
            onActivateTick: true,
            enableHtml: false,
        };
    }
    /**
     * @param message
     * @param title
     * @param timeOut
     * @param progressBar
     * @param positionClass
     */
    showSuccess(message, title, timeOut = this.DEFAULT_DELAY, progressBar, positionClass = NotificationPosition.TOP_RIGHT) {
        // const delay = this.manageTimeDelay(timeOut);
        return this.toastr.success(message, title, { timeOut, progressBar, positionClass, toastClass: 'animated fadeInDown' });
    }
    showError(message, error, title, timeOut = this.DEFAULT_DELAY, positionClass = NotificationPosition.TOP_RIGHT) {
        // const delay = this.manageTimeDelay(timeOut);
        const response = errorHelper(message, error);
        return this.toastr.error(response, title, { timeOut: timeOut === null ? this.DEFAULT_DELAY : timeOut, positionClass, toastClass: 'animated fadeInDown' });
    }
    /* *
    *
    * @param message
    * @param title
    * @param timeOut
    */
    showWarning(message, title, timeOut = this.DEFAULT_DELAY) {
        // const delay = this.manageTimeDelay(timeOut);
        return this.toastr.warning(message, title, { timeOut, toastClass: 'animated fadeInDown' });
    }
    /**
     * @param message
     * @param title
     * @param timeOut
     */
    showInfo(message, title, timeOut = this.DEFAULT_DELAY) {
        // const delay = this.manageTimeDelay(timeOut);
        return this.toastr.info(message, title, { timeOut, toastClass: 'animated fadeInDown' });
    }
    /**
     *
     * @param {string} message
     * @param title
     * @param {string} type
     */
    show(message, title, type) {
        this.toastr.show(message, // message
        title, // title
        this.individualConfig, // IndividualConfig or GlobalConfig
        type // 'toast-success', 'toast-error', 'toast-warning' or 'toast-info'
        );
    }
}
NotificationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(i0.ɵɵinject(i1.ToastrService)); }, token: NotificationService, providedIn: "root" });
