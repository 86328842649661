import { Component, OnInit } from '@angular/core';
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent} from '@angular/router';
import { Store } from '@ngrx/store';
import {NgProgress, NgProgressRef} from '@ngx-progressbar/core';
import { TokenService } from './shared/services/app-services/token.service';
import * as DomChanger from "./store/actions/dom-changes.action";
import { AppStates } from './store/app.state';


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  constructor(
    private _router: Router,
    private _progress: NgProgress,
    private _token: TokenService,
    private _store: Store<AppStates>,
  ) {
    // zoom out page
    // tslint:disable-next-line: deprecation
    document.body.style.zoom = "90%";

    this.progressRef = _progress.ref("root");

    this._router.events.subscribe((event: RouterEvent) => {
      switch (true) {
        case event instanceof NavigationStart:
          this.progressRef.start();
          break;

        case event instanceof NavigationEnd:
          this.progressRef.complete();
          break;

        case event instanceof NavigationCancel:
          this.progressRef.complete();
          break;

        case event instanceof NavigationError:
          this.progressRef.complete();
          break;

        default:
          break;
      }
    });

    // network disconnection and reconnection
    window.setInterval(() => {
      const reconnectInterval: number =
        this.reconnectInterval[AppComponent.randomIntFromInterval(0, 3)];
      const networkStatus = navigator.onLine;
      if (!networkStatus) {
        this.networkDisconnections += 1;
        this.networkStatus = networkStatus;
      }
      if (networkStatus && this.networkDisconnections > 0) {
        this.networkFeedback.reconnect = true;
        if (AppComponent.networkDivId) {
          AppComponent.networkDivId.classList.remove("network-disconnect");
          AppComponent.networkDivId.classList.add("network-reconnect");
        }
        this.networkFeedback.message = "Reconnecting";
        setTimeout(() => {
          this.networkStatus = networkStatus;
          this.networkFeedback.message = "Network disconnected!";
          this.networkFeedback.reconnect = false;
          if (AppComponent.networkDivId) {
            AppComponent.networkDivId.classList.remove("network-reconnect");
            AppComponent.networkDivId.classList.add("network-disconnect");
          }
        }, reconnectInterval);
      }
    }, 5000);

    // console welcome message
    console.log(
      "%c WELCOME TO BAXI DIGITAL BANKING",
      "color: orange; font-weight: bold; font-size: 48px"
    );
  }

  ngOnInit() {
    const counter = localStorage.getItem("browserTabCounter") || 0;
    localStorage.setItem("browserTabCounter", (Number(counter) + 1).toString());

    if (counter <= 0) {
      localStorage.removeItem("browserTabCounter");
      this._token.logoutToken();
      this._store.dispatch(new DomChanger.ChangeTitle({ data: "" }));
      this._router.navigate(["/login"]);
    }

    window.addEventListener("beforeunload", () => {
      const counter: number =
        Number(localStorage.getItem("browserTabCounter")) - 1;
      localStorage.setItem("browserTabCounter", counter.toString());
    });
  }

  // static networkDivId = `#networkStat`;
  static networkDivId = document.getElementById("networkStat");
  title = "DigitalBanking";
  loader: any;

  public networkStatus = true;
  public networkFeedback = {
    reconnect: false,
    message: "Network disconnected!",
  };
  public networkDisconnections = 0;
  public reconnectInterval: number[] = [2000, 3000, 4000, 5000];

  private progressRef: NgProgressRef;

  /**
   *
   * @param min
   * @param max
   * @returns {any}
   */
  private static randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
}
