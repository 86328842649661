import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from './components/no-auth/login/login.component';
import {AccessDeniedComponent} from './shared/components/access-denied/access-denied.component';
import {NotFoundComponent} from './shared/components/not-found/not-found.component';
import {AuthGuard} from 'src/app/shared/guards/auth.guard';
import { ChangePasswordComponent } from './components/no-auth/change-password/change-password.component';
import { ResetPasswordComponent } from './components/no-auth/reset-password/reset-password.component';


const routes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("./components/main/main.module").then((mod) => mod.MainModule),
  },
  {
    canLoad: [AuthGuard],
    path: "agent",
    loadChildren: () =>
      import("./components/agents/agents.module").then(
        (mod) => mod.AgentsModule
      ),
  },
  {
    canLoad: [AuthGuard],
    path: 'device-owner',
    loadChildren: () => import('./components/device-owner/device-owner.module').then(mod => mod.DeviceOwnerModule)
  },

  { path: 'login', component: LoginComponent },
  { path: 'update-password', component: LoginComponent },
  { path: 'access-denied', component: AccessDeniedComponent},
  // { path: 'reset-password', component: ChangePasswordComponent},
  { path: 'reset-password', component: ResetPasswordComponent},
  { path: 'not-found', component: NotFoundComponent},
  { path: '**', redirectTo: 'not-found'}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    relativeLinkResolution: 'legacy'
})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
