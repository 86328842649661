import { CanActivate, CanLoad } from '@angular/router';
import { Router, ActivatedRouteSnapshot, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { NotificationService } from '../services/app-services/notification.service';
import { TokenService } from '../services/app-services/token.service';

@Injectable()
export class AgentGuard implements CanActivate {
  constructor(private router: Router, private _notificationService: NotificationService, private _tokenService: TokenService) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (
      this._tokenService.getUserType().toUpperCase() !== "ADMIN" &&
      this._tokenService.getUserType().toUpperCase() === "BENEFICIARY" &&
      this._tokenService.getAuthUser().is_device_owner == true
    ) {
      // this._tokenService.logoutToken(); // log out save data
      // this._notificationService.showError(`You're not permitted to view this page`, null, 'Logged Out!');
      this.router.navigate(["device-owner"]);
    }
    if (
      this._tokenService.getUserType().toUpperCase() === "BENEFICIARY" &&
      this._tokenService.getAuthUser().is_device_owner === false
    ) {
      return true;
    }
    return true;
  }
}
