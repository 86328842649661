import * as responseActions from '../actions/response-data.action';
import {ResponseData} from '../actions/response-data.action';

export interface ResponseDataInterface {
  action: string;
  title?: string;
  response: any;
}

const initialState: ResponseDataInterface = {
  action: '',
  title: '',
  response: ''
};

export function responseParser(state: ResponseDataInterface = initialState, action: responseActions.Actions) {
  switch (action.type) {
    case ResponseData.responseParser:
      return {...state, ...action.payload};
    case ResponseData.responseRemover:
      return  {...state, ...action.payload};
    default :
      return state;
  }
}
