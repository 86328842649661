import * as tslib_1 from "tslib";
import { API_URLS } from '../../utilities/app-urls';
import { catchError } from 'rxjs/operators';
import { EncryptionService } from '../app-services/encryption.service';
import * as i0 from "@angular/core";
import * as i1 from "../app-services/notification.service";
import * as i2 from "../app-services/api-req-handlers.service";
import * as i3 from "../app-services/token.service";
import * as i4 from "@angular/common/http";
export class AuthService {
    constructor(notificationService, _apiService, _token, _http) {
        this.notificationService = notificationService;
        this._apiService = _apiService;
        this._token = _token;
        this._http = _http;
    }
    /**
     *
     * @param userObject
     */
    setAuthUserToken(userObject) {
        this._token.setAuthUser(userObject);
    }
    /**
     * Checks if type is logged in
     * @returns {boolean}
     */
    isLoggedIn() {
        return this._token.isTokenLogged();
    }
    /**
     *
     * @param payload
     */
    login(payload) {
        return this._apiService.post(`${API_URLS.accountService.login}`, Object.assign({}, payload, {
            password: EncryptionService.enCodeBase64(payload.password)
        }));
    }
    requestPhoneOTP(payload) {
        return this._apiService.get(`${API_URLS.accountService.requestOTP}?username=${payload}`)
            .pipe(catchError((resError) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.notificationService.showError(resError.error.resp_description);
        })));
        ;
    }
    sendPhoneOTP(payload, username) {
        return this._apiService.get(`${API_URLS.accountService.sendOTP}?code=${payload}&username=${username}`)
            .pipe(catchError((resError) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.notificationService.showError(resError.error.resp_description);
        })));
        ;
    }
    requestLoginOTP(payload) {
        return this._apiService.get(`${API_URLS.accountService.requestLoginOTP}?username=${payload}`).pipe(catchError((resError) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.notificationService.showError(resError.error.resp_description);
        })));
    }
    sendLoginOTP(payload, username) {
        return this._apiService.get(`${API_URLS.accountService.sendLoginOTP}?code=${payload}&username=${username}`)
            .pipe(catchError((resError) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.notificationService.showError(resError.error.resp_description);
        })));
    }
    /**
     * @description use to log type out
     * @returns {Observable<any>}
     */
    logout() {
        // return this._apiService.post(`${LOGOUT_URL}`);
    }
    /**
     *
     * @param payload
     */
    updatePassword(payload) {
        return this._apiService.post(`${API_URLS.accountService.passwordReset}/reset`, payload);
    }
    /**
     *
     * @param payload
     */
    forgetPassword(payload) {
        return this._apiService.post(`${API_URLS.accountService.passwordReset}/send-email`, payload);
    }
    /**
     * @param payload
     */
    changePassword(payload) {
        return this._apiService.post(`${API_URLS.accountService.account}/change-password`, payload);
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.NotificationService), i0.ɵɵinject(i2.ApiReqHandlersService), i0.ɵɵinject(i3.TokenService), i0.ɵɵinject(i4.HttpClient)); }, token: AuthService, providedIn: "root" });
