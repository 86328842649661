import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {removeSpaces} from 'src/app/shared/directives/common-form-validator.directive';
import {CUSTOM_CONSTANTS} from 'src/app/shared/utilities/config';
import {validateAllFormFields} from 'src/app/shared/utilities/helpers';
import {AuthService} from 'src/app/shared/services/api-services/auth.service';
import {NotificationService} from 'src/app/shared/services/app-services/notification.service';
import {environment as env } from 'src/environments/environment';
import {Router} from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  state = {
    loader: false
  };

  @Output() switchDom = new EventEmitter<string>();

  forgotPasswordForm: FormGroup;

  constructor(private _FB: FormBuilder, private _authService: AuthService, private _notificationService: NotificationService, private _router: Router) { }

  ngOnInit() {
    return this.forgotPasswordForm = this._FB.group({
      email: ['', [Validators.required, Validators.email, removeSpaces]],
      return_url: [env.api.PASSWORD_RETURN_URL]
    });
  }


  public goBack(): void {
    this.switchDom.emit('LOGIN_FORM');
  }

  public submit(): void {
    // first of all let validate the login form on submit of the form
    if (!this.forgotPasswordForm.valid) {
      return validateAllFormFields(this.forgotPasswordForm);
    }
    this.state.loader = true;
    this._authService.forgetPassword(this.forgotPasswordForm.value).subscribe(
      res => {
        this.state.loader = false;
        this._notificationService.showSuccess(res.resp_description, res.resp_message || CUSTOM_CONSTANTS.DEFAULT_SUCCESS_MESSAGE); // show message
        this.forgotPasswordForm.reset(); // reset form
        // this.switchDom.emit('LOGIN_FORM'); // back to login page
        this._router.navigateByUrl('update-password?option=validation');
        console.log(res);
      }, err => {
        this.state.loader = false;
        console.error(err);
        this._notificationService.showError('Error Occurred', err, err.error.resp_message || CUSTOM_CONSTANTS.DEFAULT_ERROR_MESSAGE);
      }
    );
  }

}
